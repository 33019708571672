import {
  Ingredient,
  Measurement,
  PreparationStep,
  RecipeFormIngredient,
  RecipeIngredient,
  Remark,
} from './recipes.types';

export enum RecipePreviewTranslationStatus {
  Completed = 'COMPLETED',
  Verified = 'VERIFIED',
  Error = 'ERROR',
}
export enum RecipeTranslationStatus {
  Main = 'MAIN',
  AI = 'AI',
  Verified = 'VERIFIED',
  Pending = 'PENDING',
  Error = 'ERROR',
}
export enum RecipeTranslationMethod {
  AI = 'AI',
  MEP = 'MEP',
  Human = 'HUMAN',
}

type TranslatedItem<T, N extends string[]> = T & { translation: RecipeTranslation<N> };

export type GetRecipeTranslationsParams = {
  recipeId: number;
  language: string;
};
export type UpdateRecipeTranslationsParams = GetRecipeTranslationsParams & {
  translation: RecipeTranslationsResponse;
};

export type TranslateRecipeOptions = {
  recipeId: number;
  languages?: { language: string }[];
};

export type RecipeTranslationsResponse = TranslatedItem<
  {
    id: string;
    language: string;
    name: string;
    status: RecipeTranslationStatus;
    ingredients: TranslatedRecipeIngredient[];
    preparationSteps: TranslatedPreparationStep[];
    notes: TranslatedNote[];
  },
  ['name']
>;

type RecipeFieldTranslationMethods<N extends string[] = ['']> = {
  [key in N[number]]: RecipeTranslationMethod;
};

export type RecipeTranslation<N extends string[] = ['']> = {
  [key in N[number]]: string;
} & {
  id: number;
  name: string;
  fieldTranslationMethods: RecipeFieldTranslationMethods<N>;
};

export type TranslatedMeasurement = TranslatedItem<Measurement, ['unit']>;

export type TranslatedIngredient = TranslatedItem<
  {
    id?: Ingredient['id'];
    name?: Ingredient['name'];
  },
  ['name']
>;

export type TranslatedRecipeIngredient = Omit<
  TranslatedItem<RecipeIngredient, ['amount', 'notes']>,
  'id' | 'measurement' | 'ingredient' | 'recipe'
> & {
  id: number;
  measurement: TranslatedMeasurement;
  ingredient: TranslatedIngredient | null;
  recipe: TranslatedIngredient | null;
  notes: string;
};

export type TranslatedPreparationStep = TranslatedItem<
  {
    id: number;
    order: number;
    description: PreparationStep['description'];
    image?: string;
  },
  ['description']
>;

export type TranslatedNote = Remark & {
  translation: {
    note: string;
    translationMethod: RecipeTranslationMethod;
  };
};

export type Translation = {
  language: string;
  status: RecipeTranslationStatus;
};

export type CountedTranslation = Translation & {
  verifiedPercentage?: number;
  translatedCount?: number;
  verifiedCount?: number;
  totalCount?: number;
};

export type TranslatedRecipeFormIngredient = Pick<
  RecipeFormIngredient,
  'isIngredientRecipe' | 'name' | 'refId'
> & {
  translationId: number;
  translatedName: string;
  method: RecipeTranslationMethod;
  measurements: TranslatedMeasurement[];
  note: {
    note: string;
    translatedNote: string;
    method: RecipeTranslationMethod;
  };
};
