import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { UpdateRecipeTranslationsParams } from 'types/recipe-translations.types';

const putRecipeTranslations = async (params: UpdateRecipeTranslationsParams) => {
  const { data: translations } = await apiService.putRecipeTranslations(params);
  return translations;
};

export const useUpdateRecipeTranslations = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const notifications = useNotifications();

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: putRecipeTranslations,

    onSuccess: (_, { language, recipeId }) => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.recipeTranslations.byId({ language, recipeId }),
      });
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });
      notifications.success({
        message: intl.formatMessage(
          { id: 'recipes.translation.update.success' },
          { language: intl.formatMessage({ id: `language.${language}` as I18nKey }) },
        ),
      });
    },

    onError: () => {
      notifications.error({
        message: intl.formatMessage(
          { id: 'general.actions.save.error' },
          { type: intl.formatMessage({ id: 'general.translations.label' }) },
        ),
      });
    },
  });

  return {
    updateRecipeTranslations: mutate,
    isUpdatingTranslations: isPending,
    isError,
    error,
  };
};
