import { useEffect } from 'react';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import config from 'config';

import { isDev } from 'utils/env.utils';

import { useFeatureFlags } from 'hooks/useFeatureFlags';

interface TranslationLocaleState {
  translationLocale: string;
  setTranslationLocale: (newTranslationLocale: string) => void;
}

/**
 * Store to persist the current translation locale
 */
const translationLocaleStore = create<TranslationLocaleState>()(
  devtools(
    persist(
      (set) => ({
        translationLocale: config.DEFAULT_TRANSLATION_LOCALE,
        setTranslationLocale: (newTranslationLocale) =>
          set(() => ({ translationLocale: newTranslationLocale })),
      }),
      {
        name: 'translation-locale-store',
      },
    ),
    {
      enabled: isDev(),
    },
  ),
);

export const useTranslationLocale = () => {
  const { translationsFeature } = useFeatureFlags();
  const setTranslationLocale = translationLocaleStore((state) => state.setTranslationLocale);
  useEffect(() => {
    if (!translationsFeature) {
      setTranslationLocale(config.DEFAULT_TRANSLATION_LOCALE);
    }
  }, [setTranslationLocale, translationsFeature]);

  return translationLocaleStore((state) => state);
};
