import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useDeleteLogin } from 'queries/auth';
import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { User } from 'types/user.types';

const fetchUser = async () => {
  const { data: user } = await apiService.getUser();
  return user;
};

export const UserQuery = {
  queryKey: QueryKeys.users.me(),
  queryFn: fetchUser,
};

export const useUser = (
  config: CommonQueryOptions<ApiResponse<User>, ReturnType<typeof QueryKeys.users.me>> = {},
) => {
  const navigate = useNavigate();
  const { signOut } = useDeleteLogin();

  const { data, status, isLoading, error } = useQuery({
    ...UserQuery,
    ...config,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  useEffect(() => {
    if (error?.response?.status === 404) {
      signOut(undefined, { onSuccess: () => navigate(Routes.Login, { replace: true }) });
    }
  }, [navigate, signOut, error]);

  return {
    user: data?.data || null,
    status,
    isLoading,
    error,
  };
};
