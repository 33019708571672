import { Delete as DeleteIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { noop } from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useDeleteMenu, useRecheckMenuItemMatches } from 'queries';
import { MenuFormData } from 'types/menus.types';

import { ConfirmationDialog } from 'components/@common';
import ActionMenu from 'components/@common/ActionMenu/ActionMenu';
import ActionMenuItem from 'components/@common/ActionMenu/ActionMenuItem';

const MenuActionsMenu = () => {
  const { menuCrudFeature } = useFeatureFlags();
  const intl = useIntl();
  const { getValues } = useFormContext<MenuFormData>();
  const { deleteMenu, isPending: isDeleting } = useDeleteMenu();
  const { recheckMenuItems, isPending: isCheckingMenuItems } = useRecheckMenuItemMatches();
  const navigate = useNavigate();

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const handleDeleteMenu = () => {
    if (!menuCrudFeature) return;
    deleteMenu(getValues('id'), {
      onSuccess: () => navigate(Routes.MenuOverview, { replace: true }),
    });
    setOpenConfirmDelete(false);
  };

  if (!menuCrudFeature) return;

  return (
    <Box className="no-print">
      <ActionMenu>
        <ActionMenuItem
          Icon={RefreshIcon}
          isLoading={isCheckingMenuItems}
          onClick={isCheckingMenuItems ? noop : () => recheckMenuItems(getValues('id'))}
          label={<FormattedMessage id="menus.actions.recheck_matches" />}
        />
        {menuCrudFeature && (
          <ActionMenuItem
            Icon={DeleteIcon}
            isLoading={isDeleting}
            onClick={isDeleting ? noop : () => setOpenConfirmDelete(true)}
            label={
              <FormattedMessage
                id="general.actions.delete"
                values={{ type: intl.formatMessage({ id: 'menus.label.singular' }) }}
              />
            }
          />
        )}
      </ActionMenu>

      <ConfirmationDialog
        title={
          <FormattedMessage
            id="general.actions.delete.confirm.title"
            values={{ type: intl.formatMessage({ id: 'menus.label.singular' }) }}
          />
        }
        message={<FormattedMessage id="general.actions.delete.confirm.text" />}
        onConfirm={handleDeleteMenu}
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      />
    </Box>
  );
};

export default MenuActionsMenu;
