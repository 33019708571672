import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';

const FeatureDisabledMessage = () => {
  return (
    <Alert severity="error" variant="filled">
      <AlertTitle>
        <FormattedMessage id="feature.disabled.title" />
      </AlertTitle>
      <Stack gap={3}>
        <FormattedMessage id="feature.disabled.description" />

        <Link to={Routes.Root} style={{ color: 'white' }}>
          <Button color="inherit" size="large" variant="link">
            <FormattedMessage id="notfound.button" />
          </Button>
        </Link>
      </Stack>
    </Alert>
  );
};

export default FeatureDisabledMessage;
