import { Refresh as RefreshIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { useTranslateRecipe } from 'queries';
import { useNotifications } from 'services/snackbar';

import { Tooltip } from 'components/@common/Tooltip';

type Props = {
  recipeId: number;
  language: string;
};

const RetryTranslationButton = ({ recipeId, language }: Props) => {
  const { translateRecipe } = useTranslateRecipe();
  const notifications = useNotifications();
  const intl = useIntl();

  const handleRetryTranslation = () => {
    translateRecipe(
      {
        recipeId,
        languages: [{ language }],
      },
      {
        onSuccess: () => {
          notifications.info({
            message: intl.formatMessage({ id: 'recipes.translation.retry.pending' }),
          });
        },
      },
    );
  };

  return (
    <Tooltip title={<FormattedMessage id="recipes.translation.retry" />} zIndex={1300}>
      <IconButton onClick={handleRetryTranslation} sx={{ height: 24, width: 24 }}>
        <RefreshIcon sx={{ width: 20 }} />
      </IconButton>
    </Tooltip>
  );
};

export default RetryTranslationButton;
