import { DangerousRounded, VerifiedRounded as VerifiedRoundedIcon } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { memo, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RecipeTranslationStatus } from 'types/recipe-translations.types';

import { Tooltip } from 'components/@common/Tooltip';

type Props = {
  verifiedStatus?: RecipeTranslationStatus;
  verifiedPercentage?: number;
  verifiedTooltip?: I18nKey;
  aiTooltip?: I18nKey;
  loadingComponent?: ReactNode;
  size?: 'md' | 'lg';
};

const TranslationStatus = ({
  verifiedPercentage,
  verifiedStatus,
  verifiedTooltip = 'recipes.translations.verified.tooltip',
  aiTooltip = 'recipes.translations.ai.tooltip',
  loadingComponent = <CircularProgress size={16} color="inherit" />,
  size = 'md',
}: Props) => {
  const intl = useIntl();
  const isLarge = size === 'lg';

  if (verifiedStatus === RecipeTranslationStatus.Main) {
    return (
      <Typography
        bgcolor="green.100"
        color="green.800"
        variant="chip"
        py={0.25}
        px={0.75}
        borderRadius={(theme) => theme.mixins.borderRadius.sm}
      >
        <FormattedMessage id="language.label.main" />
      </Typography>
    );
  }

  if (verifiedStatus === RecipeTranslationStatus.Error) {
    return (
      <Tooltip
        title={intl.formatMessage({ id: 'recipes.translations.error.tooltip' })}
        maxWidth={184}
        zIndex={1300}
      >
        <DangerousRounded
          sx={{ width: isLarge ? 30 : 24, height: 'auto', color: 'error.main', ml: 1 }}
        />
      </Tooltip>
    );
  }

  if (verifiedStatus === RecipeTranslationStatus.Verified) {
    return (
      <Tooltip title={intl.formatMessage({ id: verifiedTooltip })} maxWidth={184} zIndex={1300}>
        <Typography variant="buttonSmall" color="info.main" width={36} justifyContent="center">
          {!!verifiedPercentage && `${verifiedPercentage}%`}
          <VerifiedRoundedIcon
            sx={{ width: isLarge ? 30 : 24, height: 'auto', color: 'info.main', ml: 1 }}
          />
        </Typography>
      </Tooltip>
    );
  }

  if (verifiedStatus === RecipeTranslationStatus.AI) {
    return (
      <Tooltip
        title={intl.formatMessage({ id: aiTooltip })}
        disabled={!aiTooltip}
        maxWidth={184}
        zIndex={1300}
      >
        <Typography
          variant="buttonSmall"
          sx={(theme) => ({
            bgcolor: '#f0f0f0',
            px: 1,
            py: 0.25,
            color: 'info.main',
            borderRadius: theme.mixins.borderRadius.sm,
          })}
        >
          <FormattedMessage id="common.ai" />
        </Typography>
      </Tooltip>
    );
  }

  return <Box sx={{ ml: 1 }}>{loadingComponent}</Box>;
};

export default memo(TranslationStatus);
