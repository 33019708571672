import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LinkProps, useNavigate } from 'react-router-dom';

interface Props {
  text?: I18nKey;
  to?: LinkProps['to'];
  onClick?: () => void;
}

const BackButton = ({ to, onClick = () => null, text = 'overview.label' }: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick();
    if (to) {
      navigate(to);
    }
  };

  return (
    <Button onClick={handleClick} variant="text" color="inherit" className="no-print">
      <ArrowBackIcon sx={(theme) => ({ width: theme.spacing(2) })} />
      <Typography variant="buttonSmall">
        <FormattedMessage id={text} />
      </Typography>
    </Button>
  );
};

export default BackButton;
