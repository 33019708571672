import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useTransformIngredientTranslations } from 'utils/recipe-translations.utils';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { useLanguages, useRecipeTranslations, useUpdateRecipeTranslations } from 'queries';
import { useNotifications } from 'services/snackbar';
import { useTranslationLocale } from 'store';
import {
  RecipeTranslationsResponse,
  RecipeTranslationStatus,
} from 'types/recipe-translations.types';
import { RecipeFormValues } from 'types/recipes.types';

type Props = {
  recipeId: number;
  formMethods: UseFormReturn<RecipeFormValues>;
  isDraft: boolean;
};

/**
 * Hook to manage the recipe translation form
 */
export const useRecipeTranslationForm = ({ formMethods, recipeId, isDraft }: Props) => {
  const intl = useIntl();
  const notifications = useNotifications();
  const { languages } = useLanguages();

  const [isEditingTranslations, setIsEditingTranslations] = useState(false);
  const { translationLocale, setTranslationLocale } = useTranslationLocale();

  const { transformFormIngredientTranslationsToApi, transformApiIngredientTranslationsToForm } =
    useTransformIngredientTranslations();

  const { isLoading: isLoadingTranslations, refetch } = useRecipeTranslations(
    { recipeId, language: translationLocale },
    {
      enabled: translationLocale !== DEFAULT_TRANSLATION_LOCALE,
      structuralSharing: (_, newData) => {
        const newTranslation = (newData as { data: RecipeTranslationsResponse }).data;
        const transformedTranslation = {
          ...newTranslation,
          ingredients: transformApiIngredientTranslationsToForm(newTranslation.ingredients),
          initialIngredients: newTranslation.ingredients,
        };
        formMethods.setValue('translation', transformedTranslation);
      },
    },
  );

  /* Reset form translations when translation locale is default locale */
  useEffect(() => {
    if (translationLocale === DEFAULT_TRANSLATION_LOCALE) {
      formMethods.setValue('translation', undefined);
    }
  }, [formMethods, translationLocale]);

  /* Set locale to default when recipe is draft */
  useEffect(() => {
    if (isDraft) {
      setTranslationLocale(DEFAULT_TRANSLATION_LOCALE);
    }
  }, [isDraft, setTranslationLocale]);

  const { updateRecipeTranslations, isUpdatingTranslations } = useUpdateRecipeTranslations();

  const submitTranslations = useCallback(() => {
    setIsEditingTranslations(false);
    const translation = formMethods.getValues('translation');

    if (!translation) {
      notifications.info({
        message: intl.formatMessage({ id: 'general.actions.save.no_changes' }),
      });
      return;
    }

    const transformedTranslation: RecipeTranslationsResponse = {
      ...translation,
      ingredients: transformFormIngredientTranslationsToApi(
        translation.ingredients,
        translation.initialIngredients,
      ),
      status: RecipeTranslationStatus.Verified,
    };

    updateRecipeTranslations({
      recipeId,
      language: translation.language,
      translation: transformedTranslation,
    });
  }, [
    formMethods,
    intl,
    notifications,
    recipeId,
    transformFormIngredientTranslationsToApi,
    updateRecipeTranslations,
  ]);

  const handleSubmit = useCallback(async () => {
    try {
      const isValid = await formMethods.trigger('translation');
      if (isValid) {
        submitTranslations();
      }
    } catch (error) {
      notifications.error({
        message: intl.formatMessage(
          { id: 'general.actions.save.error' },
          { type: intl.formatMessage({ id: 'general.translations.label' }) },
        ),
      });
    }
  }, [formMethods, intl, notifications, submitTranslations]);

  const toggleEditTranslations = () => {
    if (isEditingTranslations) {
      setIsEditingTranslations(false);
      setTranslationLocale(DEFAULT_TRANSLATION_LOCALE);
    } else {
      if (translationLocale === DEFAULT_TRANSLATION_LOCALE) {
        const localeToTranslate = languages.find((lang) => lang !== DEFAULT_TRANSLATION_LOCALE);
        if (localeToTranslate) {
          setTranslationLocale(localeToTranslate);
        }
      }
      if (formMethods.getValues('translation.language') !== translationLocale) {
        refetch();
      }
      setIsEditingTranslations(true);
    }
  };

  return {
    isLoadingTranslations,
    isUpdatingTranslations,
    submitTranslations: handleSubmit,
    translationLocale,
    setTranslationLocale,
    isEditingTranslations,
    toggleEditTranslations,
  };
};
