import { Box, Chip, Rating, TableCell, Typography } from '@mui/material';
import { formatRelative } from 'date-fns';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { lineClamp } from 'utils/text.utils';

import {
  COUNTRIES_ALL,
  RECIPE_TABLE_IMAGE_HEIGHT,
  RECIPE_TABLE_IMAGE_WIDTH,
  recipeRequirementsMap,
  recipeStatusesMap,
  recipeTypesMap,
} from 'constants/recipes.constants';
import {
  RecipePreviewTranslationStatus,
  RecipeTranslationStatus,
} from 'types/recipe-translations.types';
import {
  RecipePreview,
  RecipeRequirement,
  RecipeTableColumns,
  RecipeTableHeader,
} from 'types/recipes.types';

import { CountryFlag, Image } from 'components/@common';
import { TranslationStatus } from 'components/@recipe-translations';

import { RecipeSeasonsChips } from '../RecipeSeasonsChips';
import { StatusChip } from './RecipeTable.style';

type Props = {
  recipe: RecipePreview;
  tableHeaders: RecipeTableHeader[];
  isShowingTranslations?: boolean;
};

const RecipeTableRow = ({
  tableHeaders,
  recipe: {
    batchImage,
    name,
    status,
    requirementType,
    type,
    seasons,
    updatedAt,
    country,
    translation,
  },
  isShowingTranslations,
}: Props) => {
  const usedHeaders = tableHeaders.map(({ id }) => id);

  return (
    <>
      <TableCell key={RecipeTableColumns.Image} align="center">
        <Image
          src={batchImage}
          alt={name}
          height={RECIPE_TABLE_IMAGE_HEIGHT}
          width={RECIPE_TABLE_IMAGE_WIDTH}
        />
      </TableCell>
      {usedHeaders.map((header) => {
        switch (header) {
          case RecipeTableColumns.Name:
            return (
              <TableCell
                key={RecipeTableColumns.Name}
                sx={(theme) => ({ minWidth: theme.spacing(24) })}
              >
                <Typography sx={lineClamp(2)}>
                  <RecipeName
                    name={name}
                    translatedName={isShowingTranslations ? translation?.name : ''}
                  />
                </Typography>
                <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <FormattedMessage
                    id={
                      !!requirementType
                        ? recipeRequirementsMap[requirementType]
                        : 'recipes.requirements.none'
                    }
                  />
                </Typography>
              </TableCell>
            );
          case RecipeTableColumns.Type:
            return (
              <TableCell key={RecipeTableColumns.Type}>
                <Chip
                  variant="label"
                  label={
                    <FormattedMessage id={!!type ? recipeTypesMap[type] : 'recipes.types.none'} />
                  }
                />
              </TableCell>
            );
          case RecipeTableColumns.Season:
            return (
              <TableCell key={RecipeTableColumns.Season}>
                {!seasons?.length ? (
                  <Chip variant="label" label={<FormattedMessage id={'general.seasons.none'} />} />
                ) : (
                  <RecipeSeasonsChips seasons={seasons} />
                )}
              </TableCell>
            );
          case RecipeTableColumns.UpdatedAt:
            return (
              <TableCell key={RecipeTableColumns.UpdatedAt}>
                <Typography variant="body2" sx={{ ...lineClamp(1), textTransform: 'capitalize' }}>
                  {formatRelative(updatedAt, new Date())}
                </Typography>
              </TableCell>
            );
          case RecipeTableColumns.Performance:
            return (
              <TableCell key={RecipeTableColumns.Performance}>
                <Rating value={0.5} />
              </TableCell>
            );
          case RecipeTableColumns.Translation:
            let verifiedStatus = RecipeTranslationStatus.Pending;
            if (!!translation) {
              verifiedStatus =
                translation.status === RecipePreviewTranslationStatus.Verified
                  ? RecipeTranslationStatus.Verified
                  : RecipeTranslationStatus.AI;
            }

            return (
              <TableCell key={RecipeTableColumns.Translation} align="center">
                {isShowingTranslations && (
                  <TranslationStatus
                    size="lg"
                    verifiedStatus={verifiedStatus}
                    loadingComponent={
                      <Chip
                        label={<FormattedMessage id="recipes.translation.pending" />}
                        variant="soft"
                        sx={{ color: 'rgba(0, 0, 0, 0.6)', height: 24 }}
                      />
                    }
                  />
                )}
              </TableCell>
            );
          case RecipeTableColumns.Countries:
            return (
              <TableCell key={RecipeTableColumns.Countries}>
                <Box display="flex" justifyContent="center">
                  {requirementType === RecipeRequirement.Core || country === COUNTRIES_ALL ? (
                    <Chip variant="labelDark" label={<FormattedMessage id="base.all" />} />
                  ) : (
                    <CountryFlag country={country} />
                  )}
                </Box>
              </TableCell>
            );
          case RecipeTableColumns.Status:
            return (
              <TableCell key={RecipeTableColumns.Status}>
                <StatusChip
                  status={status}
                  label={<FormattedMessage id={recipeStatusesMap[status]} />}
                />
              </TableCell>
            );
        }
      })}
    </>
  );
};

export default memo(RecipeTableRow);

const RecipeName = ({ translatedName, name }: { translatedName?: string; name?: string }) => {
  if (!!translatedName) {
    return translatedName;
  }
  if (!!name) {
    return name;
  }
  return <FormattedMessage id="recipes.label.untitled" />;
};
