import { Country } from 'constants/countries.constants';

export enum UserLanguage {
  EN = 'EN',
  NL = 'NL',
  FR = 'FR',
}

export enum UserRole {
  HQ = 'HQ',
  Franchisee = 'FRANCHISEE',
  Admin = 'ADMIN',
}
export type UserGroup = {
  id: number;
  name: string;
  country: Country;
};

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  createdAt: string;
  updatedAt: string;
  group: UserGroup;
  language?: UserLanguage;
  featureFlags: FeatureFlag[];
};

export enum FeatureFlag {
  Translations = 'TRANSLATIONS',

  CreateRecipe = 'CREATE_RECIPE',
  UpdateRecipe = 'UPDATE_RECIPE',
  DeleteRecipe = 'DELETE_RECIPE',
  ExportRecipe = 'EXPORT_RECIPE',

  CreateMenu = 'CREATE_MENU',
  UpdateMenu = 'UPDATE_MENU',
  DeleteMenu = 'DELETE_MENU',
  ExportMenu = 'EXPORT_MENU',

  CreateMenuItem = 'CREATE_MENU_ITEM',
  UpdateMenuItem = 'UPDATE_MENU_ITEM',
  DeleteMenuItem = 'DELETE_MENU_ITEM',
}
