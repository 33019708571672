import {
  CheckRounded as CheckRoundedIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Collapse, collapseClasses, IconButton, Stack } from '@mui/material';
import { m } from 'framer-motion';
import { isEqual, noop, uniqueId } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { listItemAnimations } from 'constants/animation.constants';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useUpdateMenuItem } from 'queries';
import { MenuItem, MenuItemStatus } from 'types/menus.types';
import { RecipePreview, RecipeStatus } from 'types/recipes.types';

import MenuItemForm from 'components/@menu-form/MenuItems/MenuItemRow/MenuItemForm';

import MenuItemInfo from './MenuItemInfo';

type SectionMenuItemProps = {
  menuId: number;
  menuItem: MenuItem;
  isActive: boolean;
  menuItemSections: string[];
  disabled: boolean;
  isSelected: boolean;
  toggleSelected: (menuItem: MenuItem) => void;
  onRemove: (id: number) => void;
  onClick: (menuItem: MenuItem) => void;
};

const MenuItemRow = ({
  menuId,
  menuItem,
  isActive,
  menuItemSections,
  disabled,
  isSelected,
  toggleSelected,
  onRemove,
  onClick,
}: SectionMenuItemProps) => {
  const uniqueFormId = useRef(uniqueId());
  const { menuCrudFeature } = useFeatureFlags();

  const { updateMenuItem, isPending: isUpdatingMenuItem } = useUpdateMenuItem();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!menuCrudFeature) {
      setIsEditing(false);
    }
  }, [menuCrudFeature]);

  const [isConfirmingRemove, setIsConfirmingRemove] = useState(false);

  const handleSave = useCallback(
    (values: MenuItem) => {
      if (!menuCrudFeature) {
        setIsEditing(false);
        return;
      }

      if (
        isEqual(
          { name: values.name, description: values.description, section: values.section },
          { name: menuItem.name, description: menuItem.description, section: menuItem.section },
        )
      ) {
        setIsEditing(false);
        return;
      }

      updateMenuItem(
        {
          ...values,
          menuId,
          recipe: values.recipe || { id: null },
          isPurchasedItem: values.isPurchasedItem ?? false,
        },
        { onSuccess: () => setIsEditing(false) },
      );
    },
    [
      menuId,
      menuItem.description,
      menuItem.name,
      menuItem.section,
      updateMenuItem,
      menuCrudFeature,
    ],
  );

  const color = !!menuItem.isPurchasedItem
    ? 'bluegrey.500'
    : [MenuItemStatus.Approved, MenuItemStatus.Matched, MenuItemStatus.ManualOverride].includes(
          menuItem.status,
        )
      ? 'green.500'
      : 'warning.main';

  const onEdit = useCallback(() => setIsEditing(true), []);

  // if we're in readOnly/disabled mode, we can still open the drawer if the menuItem has a recipe
  const canOpenDrawer = !disabled || (menuItem?.recipe && 'name' in menuItem?.recipe);

  return (
    <m.div key={menuItem.id} layoutId={String(menuItem.id)} {...listItemAnimations}>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        key={menuItem.id}
        pr={1.5}
        py={3}
        borderLeft="3px solid"
        borderColor={color}
        width="100%"
        height={isEditing ? 120 : 88}
        bgcolor={isActive ? 'grey.100' : 'bg.paper'}
        sx={(theme) => ({
          backgroundImage:
            menuItem.recipe?.id &&
            (menuItem.recipe as RecipePreview).status !== RecipeStatus.Published
              ? 'repeating-linear-gradient(-45deg, transparent 24px 28px, rgba(0,0,0,0.05) 20px 30px)'
              : 'unset',
          borderBottom: `1px solid ${theme.palette.divider}`,
          cursor: canOpenDrawer ? 'pointer' : 'default',
          transition: 'all 0.25s',
          '&:hover': { bgcolor: 'grey.100' },
          '&:hover .edit-button': { opacity: 1 },
        })}
        onClick={!canOpenDrawer || isEditing ? noop : () => onClick(menuItem)}
      >
        {disabled || !menuCrudFeature ? (
          <Box ml={2} />
        ) : (
          <Checkbox
            sx={{ color: 'grey.500', p: 1.5 }}
            checked={isSelected}
            onClick={(e) => {
              e.stopPropagation();
              toggleSelected(menuItem);
            }}
          />
        )}

        <Collapse
          in={isEditing && menuCrudFeature}
          orientation="horizontal"
          mountOnEnter
          unmountOnExit
          timeout={400}
          sx={{
            [`&.${collapseClasses.entered}`]: { width: '100% !important' },
            [`& .${collapseClasses.wrapper}`]: { width: '100% !important' },
            [`& .${collapseClasses.wrapperInner}`]: { width: '100% !important' },
          }}
        >
          <MenuItemForm
            menuItem={menuItem}
            isDisabled={isUpdatingMenuItem}
            menuItemSections={menuItemSections}
            uniqueFormId={uniqueFormId.current}
            onSave={handleSave}
          />
        </Collapse>

        <MenuItemInfo
          isEditing={isEditing}
          onEdit={onEdit}
          menuItem={menuItem}
          disabled={disabled}
          onOpenDrawer={onClick}
        />

        <Box display="flex" alignItems="center" height="100%">
          <Collapse
            in={isEditing && !isConfirmingRemove}
            orientation="horizontal"
            unmountOnExit
            mountOnEnter
            sx={{ height: '100%' }}
          >
            <Box display="flex" gap={1} height="100%">
              <LoadingButton
                variant="contained"
                color="success"
                loading={isUpdatingMenuItem}
                form={uniqueFormId.current}
                type="submit"
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'text.secondary',
                  width: 75,
                  gap: 1,
                  px: 2.5,
                  py: 2,
                  ...theme.typography.buttonSmall,
                  textTransform: 'none',
                })}
              >
                <CheckRoundedIcon sx={{ width: 24 }} />
                <FormattedMessage id="general.actions.done" />
              </LoadingButton>
              {menuCrudFeature && (
                <Button
                  variant="contained"
                  type="button"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsConfirmingRemove(true);
                  }}
                  sx={(theme) => ({
                    bgcolor: 'red.600',
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'text.secondary',
                    width: 75,
                    gap: 1,
                    px: 2.5,
                    py: 2,
                    ...theme.typography.buttonSmall,
                    textTransform: 'none',
                  })}
                >
                  <DeleteIcon sx={{ width: 24 }} />
                  <FormattedMessage id="general.button.remove" />
                </Button>
              )}
            </Box>
          </Collapse>
          <Collapse in={isConfirmingRemove} orientation="horizontal" mountOnEnter unmountOnExit>
            <Stack direction="row" gap={1} alignItems="center">
              <Button
                variant="contained"
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(menuItem.id);
                }}
                sx={(theme) => ({
                  bgcolor: 'red.600',
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'text.secondary',
                  width: 114,
                  gap: 1,
                  px: 2.5,
                  py: 2,
                  ...theme.typography.buttonSmall,
                  textTransform: 'none',
                })}
              >
                <DeleteIcon sx={{ width: 24 }} />
                <FormattedMessage id="general.actions.are_you_sure" />
              </Button>

              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsConfirmingRemove(false);
                }}
                sx={{ width: 36, height: 36 }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Collapse>
        </Box>
      </Stack>
    </m.div>
  );
};

export default memo(MenuItemRow);
