import { Autocomplete, autocompleteClasses, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useTransformRecipe } from 'utils/recipe.utils';

import { MEP_PREFIX } from 'constants/recipes.constants';
import { useSearchState } from 'hooks';
import { useIsHQ } from 'hooks/useIsHQ';
import { useRecipeById, useRecipes, useUser } from 'queries';
import {
  RecipeAutocompleteOption,
  RecipeFormValues,
  RecipeRequirement,
  RecipeStatus,
  RecipeType,
} from 'types/recipes.types';

import { ConfirmationDialog } from 'components/@common';

import { AutocompleteOption, OptionSkeleton, RecipeNameField } from './components';

type Props = {
  isReadOnly: boolean;
  onSave: (options: { silent: boolean }) => void;
  isEditingTranslations: boolean;
  isShowingTranslations: boolean;
  isLoadingTranslations: boolean;
};

const removeMepPrefix = (name: string) =>
  name.substring(0, MEP_PREFIX.length) === MEP_PREFIX ? name.replace(MEP_PREFIX, '') : name;

const RecipeNameAutocomplete = ({
  isReadOnly,
  onSave,
  isEditingTranslations,
  isShowingTranslations,
  isLoadingTranslations,
}: Props) => {
  const { user } = useUser();
  const isHQ = useIsHQ();
  const { setValue, watch } = useFormContext<RecipeFormValues>();

  const name = watch('name');
  const type = watch('type') as RecipeType;
  const isMEP = type === RecipeType.MiseEnPlace;

  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState({
    initialValue: name,
  });
  const translatedName = watch('translation.translation.name');

  useEffect(() => {
    if (isReadOnly) return;

    setValue('name', `${isMEP ? MEP_PREFIX : ''}${removeMepPrefix(searchValue)}`, {
      shouldDirty: true,
    });
  }, [isReadOnly, isMEP, searchValue, setValue]);

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data: recipes,
    isFetching,
  } = useRecipes({ search: debouncedSearchValue || '' }, { enabled: !isReadOnly });

  const options: RecipeAutocompleteOption[] = recipes
    .filter(({ status }) => status === RecipeStatus.Published)
    .map(({ id, ...rest }) => ({ id, ...rest }));

  const getNext = (inView: boolean) => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const { transformApiRecipeToForm } = useTransformRecipe();

  const [recipeOverwriterId, setRecipeOverwriterId] = useState<number | null>(null);

  const { data: recipeOverwriter } = useRecipeById(recipeOverwriterId || 0, {
    retry: 0,
    enabled: !!recipeOverwriterId,
  });

  const handleOverwriteRecipe = () => {
    if (!recipeOverwriterId) return;
    const formattedRecipe = transformApiRecipeToForm(recipeOverwriter, true);
    handleSearchChange(formattedRecipe.name);
    setValue('name', formattedRecipe.name);
    setValue('description', formattedRecipe.description);
    setValue('category', formattedRecipe.category);
    setValue('type', formattedRecipe.type);
    setValue('seasons', formattedRecipe.seasons);
    setValue('image', formattedRecipe.image);
    setValue('batchImage', formattedRecipe.batchImage);
    setValue('ingredients', formattedRecipe.ingredients);
    setValue('preparationSteps', formattedRecipe.preparationSteps);
    setValue('notes', formattedRecipe.notes);

    // HQ users can create recipes in any country and requirement type, franchisees can only create in their country and no CORE recipes
    setValue(
      'requirementType',
      isHQ
        ? formattedRecipe.requirementType
        : formattedRecipe.requirementType === RecipeRequirement.Core
          ? null
          : formattedRecipe.requirementType,
    );
    setValue('country', isHQ ? formattedRecipe.country : user?.group.country || '');
    onSave({ silent: false });
  };

  if (isLoadingTranslations) {
    return <Skeleton height={40} width="100%" sx={{ minWidth: 300 }} />;
  }

  if (isReadOnly && !isEditingTranslations) {
    return (
      <Typography variant="h4" minHeight={52} pt={0.5}>
        {isShowingTranslations ? translatedName : name}
      </Typography>
    );
  }

  return (
    <>
      <Autocomplete
        value={removeMepPrefix(searchValue)}
        options={options}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        ListboxProps={{ role: 'list-box' }} // needed to stop scroll from resetting
        loading={isFetching}
        clearOnEscape
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        inputValue={removeMepPrefix(searchValue)}
        onInputChange={(_, value) => handleSearchChange(value)}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        loadingText={<OptionSkeleton />}
        sx={{ width: '100%' }}
        slotProps={{
          paper: { sx: { [`& .${autocompleteClasses.listbox}`]: { padding: '0 !important' } } },
        }}
        renderOption={(_, option, state) => (
          <AutocompleteOption
            key={option.id}
            allOptions={options}
            option={option}
            inputValue={state.inputValue}
            index={state.index}
            onChange={(recipe) => handleSearchChange(recipe.name)}
            onOverwriteFromRecipe={() => setRecipeOverwriterId(option.id || null)}
            onGetNextOptions={getNext}
          />
        )}
        disableClearable
        renderInput={(props) => <RecipeNameField {...props} isMEP={isMEP} />}
      />

      <ConfirmationDialog
        title={<FormattedMessage id="recipes.actions.overwrite_values.confirm.title" />}
        message={<FormattedMessage id="recipes.actions.overwrite_values.confirm.text" />}
        open={!!recipeOverwriterId}
        onConfirm={handleOverwriteRecipe}
        onClose={() => setRecipeOverwriterId(null)}
      />
    </>
  );
};

export default RecipeNameAutocomplete;
