import { useQueryClient } from '@tanstack/react-query';

import { LanguageQuery, UserQuery } from 'queries';

/**
  This hook is used to prefetch data on app load.
  Add queries as described in the comment.
*/
export const usePrefetch = () => {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(LanguageQuery);
  queryClient.prefetchQuery(UserQuery);
  /* queryClient.prefetchQuery(anotherQuery); */
};
