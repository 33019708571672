import { useUser } from 'queries';
import { FeatureFlag } from 'types/user.types';

type FeatureFlags = {
  translationsFeature: boolean;
  menuCrudFeature: boolean;
  menuExportFeature: boolean;
  recipeCrudFeature: boolean;
  recipeExportFeature: boolean;
};

const flagsMap: { [key: string]: FeatureFlag[] } = {
  translationsFeature: [FeatureFlag.Translations],
  menuCrudFeature: [
    FeatureFlag.CreateMenu,
    FeatureFlag.UpdateMenu,
    FeatureFlag.DeleteMenu,
    FeatureFlag.CreateMenuItem,
    FeatureFlag.UpdateMenuItem,
    FeatureFlag.DeleteMenuItem,
  ],
  menuExportFeature: [FeatureFlag.ExportMenu],
  recipeCrudFeature: [FeatureFlag.CreateRecipe, FeatureFlag.UpdateRecipe, FeatureFlag.DeleteRecipe],
  recipeExportFeature: [FeatureFlag.ExportRecipe],
};

export const useFeatureFlags = (): FeatureFlags => {
  const { user } = useUser();
  const featureFlags = user?.featureFlags || [];

  return {
    translationsFeature: flagsMap.translationsFeature.every((flag) => featureFlags.includes(flag)),
    menuCrudFeature: flagsMap.menuCrudFeature.every((flag) => featureFlags.includes(flag)),
    menuExportFeature: flagsMap.menuExportFeature.every((flag) => featureFlags.includes(flag)),
    recipeCrudFeature: flagsMap.recipeCrudFeature.every((flag) => featureFlags.includes(flag)),
    recipeExportFeature: flagsMap.recipeExportFeature.every((flag) => featureFlags.includes(flag)),
  };
};
