import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  menuItemClasses,
  outlinedInputClasses,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { getLanguageKey } from 'utils/recipe-translations.utils';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { useSortLanguages } from 'hooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useTranslationLocale } from 'store';
import { RecipeTranslationStatus, Translation } from 'types/recipe-translations.types';

type Props = {
  disabled?: boolean;
  recipeTranslations?: Translation[];
  prefix?: ReactNode;
  suffix?: (lang: string, status?: RecipeTranslationStatus) => ReactNode;
  menuItemSuffix?: (lang: string, status: RecipeTranslationStatus) => ReactNode;
  isEditingTranslations?: boolean;
  disableOptions?: boolean;
};

/* @TODO: optimize props/logic -> might need to split for 2 usecases (cookbook / recipe detail) */
const TranslationSelector = ({
  disabled,
  recipeTranslations = [],
  prefix,
  suffix,
  menuItemSuffix,
  isEditingTranslations,
  disableOptions,
}: Props) => {
  const { translationLocale, setTranslationLocale } = useTranslationLocale();
  const languages = useSortLanguages(recipeTranslations);
  const { translationsFeature } = useFeatureFlags();

  const handleSetTranslationLocale = (lang: string) => {
    const status = languages.find((l) => l.language === lang)?.status;
    if (disableOptions && status === RecipeTranslationStatus.Error) return;

    setTranslationLocale(lang);
  };

  if (!languages || !translationsFeature) return null;

  return (
    <Select
      disabled={disabled}
      value={translationLocale}
      defaultValue={DEFAULT_TRANSLATION_LOCALE}
      onChange={(e) => handleSetTranslationLocale(e.target.value)}
      IconComponent={KeyboardArrowDown}
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
        sx: { mt: 1.5 },
      }}
      slotProps={{
        root: {
          sx: (theme) => ({
            minWidth: 120,
            height: 40,
            pb: 0.25,
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.black[10],
            },
            [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.black[10],
              borderWidth: 1,
            },
          }),
        },
      }}
      renderValue={(selected) => (
        <Stack direction="row" alignItems="center" gap={2}>
          {prefix}
          <Typography variant="button">
            <FormattedMessage id={getLanguageKey(selected)} />
          </Typography>
          {!!suffix && (
            <Box mr={1}>
              {suffix(selected, languages.find((l) => l.language === selected)?.status)}
            </Box>
          )}
        </Stack>
      )}
    >
      {languages?.map(({ language, status }) => (
        <MenuItem
          key={language}
          value={language}
          selected={language === translationLocale}
          disabled={
            (language === DEFAULT_TRANSLATION_LOCALE && isEditingTranslations) ||
            (disableOptions &&
              language !== DEFAULT_TRANSLATION_LOCALE &&
              status === RecipeTranslationStatus.Pending)
          }
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            // Correct override could not be found, hence !important
            [`&.${menuItemClasses.selected}`]: {
              backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
            },
            [`&.${menuItemClasses.selected}:hover`]: {
              backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
            },
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
          }}
        >
          <ListItemText>
            <FormattedMessage id={getLanguageKey(language)} />
          </ListItemText>

          {menuItemSuffix && (
            <ListItemIcon sx={{ ml: 2 }}>{menuItemSuffix(language, status)}</ListItemIcon>
          )}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TranslationSelector;
