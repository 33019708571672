import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { IconButton, inputBaseClasses, MenuItem, Select, svgIconClasses } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useCountryName } from 'utils/recipe.utils';

import { COUNTRIES } from 'constants/countries.constants';
import { RecipeFormValues } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';

type Props = {
  readOnly?: boolean;
};

const CountrySelect = ({ readOnly }: Props) => {
  const getCountryName = useCountryName();
  const { control } = useFormContext<RecipeFormValues>();

  return (
    <Controller
      name="country"
      control={control}
      render={({ field: { value, onChange, ...field } }) => {
        return (
          <Select
            {...field}
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty
            readOnly={readOnly}
            variant="standard"
            renderValue={() => (
              <IconButton sx={{ p: 0 }}>
                <CountryFlag country={value} size={22} />
              </IconButton>
            )}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            sx={(theme) => ({
              bgcolor: 'transparent',
              cursor: readOnly ? 'default' : 'pointer',
              width: 48,
              p: 0,
              pb: 0.5,
              [`& .${inputBaseClasses.input}`]: {
                p: '0 !important',
                '&:focus': {
                  bgcolor: 'transparent',
                },
              },
              [`& .${svgIconClasses.root}`]: {
                width: 24,
                color: theme.palette.black[100],
              },
            })}
          >
            {Object.entries(COUNTRIES).map(([country, value]) => (
              <MenuItem key={value} value={country} sx={{ gap: 1 }}>
                <CountryFlag country={country} />
                {getCountryName(country)}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
};

export default CountrySelect;
