import { useMutation } from '@tanstack/react-query';

import { apiService } from 'services';
import { ExportRecipeParams } from 'types/recipes.types';

const exportRecipe = async (params: ExportRecipeParams) => {
  const res = await apiService.exportRecipe(params);
  return res;
};

export const useExportRecipe = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: exportRecipe,
  });

  return {
    exportRecipe: mutate,
    isPending,
  };
};
