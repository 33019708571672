import { Add as AddIcon, Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Collapse,
  collapseClasses,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { memo, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMedia } from 'hooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type Props = {
  searchValue: string;
  onSearchChange: (value: string) => void;
  onClearSearch: () => void;
  onCreateMenuItem: () => void;
  isCreatingMenuItem: boolean;
  searchEnabled: boolean;
  setSearchEnabled: (enabled: boolean) => void;
  disabled: boolean;
};

const MenuItemsActions = ({
  searchValue,
  onSearchChange,
  onClearSearch,
  onCreateMenuItem,
  isCreatingMenuItem,
  searchEnabled,
  setSearchEnabled,
  disabled,
}: Props) => {
  const { menuCrudFeature } = useFeatureFlags();
  const intl = useIntl();
  const { sm, md } = useMedia();
  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  const handleOpenSearch = () => {
    setSearchEnabled(true);
    setTimeout(() => searchFieldRef.current?.focus(), 100);
  };
  const handleCloseSearch = useCallback(() => {
    onClearSearch();
    setSearchEnabled(false);
  }, [onClearSearch, setSearchEnabled]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      px={1}
      width="100%"
      height="100%"
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'justify-between',
          gap: 2,
        },
      })}
    >
      <Collapse
        in={searchEnabled}
        orientation="horizontal"
        sx={{
          width: sm && searchEnabled ? '100% !important' : undefined,
          [`& .${collapseClasses.wrapperInner}`]: { width: '100%' },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
          height="100%"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'flex-start',
            },
          })}
        >
          <TextField
            placeholder={intl.formatMessage({ id: 'menus.items.search' })}
            onChange={(e) => onSearchChange(e.target.value)}
            onFocus={(e) => e.target.select()}
            value={searchValue}
            variant="outlined"
            size="small"
            sx={{ flex: 1, width: '100%' }}
            inputRef={searchFieldRef}
            InputProps={{
              fullWidth: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: 24 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton sx={{ width: 24, height: 24 }} onClick={handleCloseSearch}>
                    <CloseIcon sx={{ width: 20 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Collapse>
      <Collapse in={!searchEnabled} orientation="horizontal" sx={{ height: '100%' }}>
        <Stack
          direction="row"
          gap={sm ? 2 : md ? 1 : 2.5}
          py={1.5}
          px={0.5}
          alignItems="center"
          height="100%"
          width="100%"
        >
          <IconButton sx={{ width: 36, height: 36, display: 'flex' }} onClick={handleOpenSearch}>
            <SearchIcon sx={{ width: 24 }} />
          </IconButton>
          {!disabled && menuCrudFeature && (
            <LoadingButton
              loading={isCreatingMenuItem}
              onClick={onCreateMenuItem}
              variant="contained"
              color="primary"
              sx={(theme) => ({
                maxHeight: '100%',
                ...theme.typography.buttonSmall,
                textTransform: 'none',
              })}
            >
              <AddIcon sx={{ width: 16 }} />
              <FormattedMessage id="menus.items.add" />
            </LoadingButton>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default memo(MenuItemsActions);
