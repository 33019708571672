import { DialogContentText, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  title: I18nKey;
  description: I18nKey;
  index: number;
  children: ReactNode;
};

const ExporterSection = ({ title, description, index, children }: Props) => {
  return (
    <Stack>
      <Stack direction="row" gap={1} mb={1}>
        <Typography
          variant="chip"
          sx={(theme) => ({
            bgcolor: 'global01.main',
            width: theme.spacing(3),
            height: theme.spacing(3),
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white.100',
          })}
        >
          {index}
        </Typography>
        <Typography variant="body1" fontWeight="medium">
          <FormattedMessage id={title} />
        </Typography>
      </Stack>
      <DialogContentText mb={2.5}>
        <FormattedMessage id={description} />
      </DialogContentText>

      {children}
    </Stack>
  );
};

export default ExporterSection;
