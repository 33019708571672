import { Country } from 'constants/countries.constants';

import { Season, TableHeader } from './common.types';
import {
  RecipePreviewTranslationStatus,
  RecipeTranslation,
  RecipeTranslationsResponse,
  TranslatedRecipeFormIngredient,
  TranslatedRecipeIngredient,
  Translation,
} from './recipe-translations.types';

export enum RecipeType {
  Recipe = 'NORMAL',
  MiseEnPlace = 'MEP',
  Formula = 'FORMULA',
  PurchasedItem = 'PURCHASED_ITEM',
}
export enum RecipeRequirement {
  Core = 'CORE',
  Required = 'REQUIRED',
  Local = 'LOCAL',
}
export enum RecipeStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED',
}
export enum RecipeCategory {
  BreakfastBrunch = 'BREAKFAST_BRUNCH',
  Tartines = 'TARTINES',
  Salads = 'SALADS',
  HotDishesOther = 'HOT_DISHES_OTHER',
  SupplementsExtra = 'SUPPLEMENTS_EXTRA',
  DessertsPastry = 'DESSERTS_PASTRY',
  Beverages = 'BEVERAGES',
  Catering = 'CATERING',
  ExpressOffer = 'EXPRESS_OFFER',
}

export enum RecipeNoteSections {
  Allergens = 'ALLERGENS',
  MEP = 'MEP',
  CCP = 'CCP',
  Packaging = 'PACKAGING',
  Receiving = 'RECEIVING',
  Remarks = 'REMARKS',
  Service = 'SERVICE',
  ShelfLife = 'SHELF_LIFE',
  Takeaway = 'TAKEAWAY',
  Tools = 'TOOLS',
  UsedFor = 'USED_FOR',
  Yield = 'YIELD',
}

export type Remark = {
  note: string;
  section?: RecipeNoteSections;
};

export type RejectionComment = {
  id: number;
  message: string;
  createdAt: string;
};

export type RecipePerformanceData = {
  foodCost: number;
  easyToExecute: number;
  unitsSold: number;
  customerFeedback: number;
  updatedAt: string;
};

interface RecipeBase {
  id: number | null;
  name: string;
  image: string | null; // mep image
  country: Country;
  category: RecipeCategory | null;
  batchImage: string | null; // plating image
  type: RecipeType | null;
  requirementType: RecipeRequirement | null;
  onBrand: {
    score: number | null;
  };
  status: RecipeStatus;
  seasons: Season[];
  createdAt?: string;
  updatedAt: string;
}
export interface RecipePreview extends RecipeBase {
  description?: string;
  translation?: RecipeTranslation<['name']> & {
    status: RecipePreviewTranslationStatus;
  };
}
export interface RecipeDetailed extends Omit<RecipeBase, 'id'> {
  id: number;
  description: string;
  onBrandErrors?: RecipeOnBrandnessRuleSet[];
  ingredients: RecipeIngredient[];
  preparationSteps: PreparationStep[];
  notes?: Remark[];
  traceId?: string;
  comments: RejectionComment[];
  translations?: Translation[];
  performanceData: RecipePerformanceData;
}

export type GetRecipeOptions = {
  page?: number;
  page_size?: number;
  search?: string;
  category?: RecipeCategory[];
  language?: string;
  country?: Country[];
  requirement_type?: RecipeRequirement[];
  season?: Season[];
  status?: RecipeStatus[];
  type?: RecipeType[];
  sort?: string;
};

/* Recipe Filters */
export enum BaseRecipeFilters {
  requirement = 'requirement',
  category = 'category',
  type = 'type',
}
export enum OptionalRecipeFilters {
  country = 'country',
  season = 'season',
  status = 'status',
}
export type RecipeFilters = BaseRecipeFilters | OptionalRecipeFilters;

export enum RecipeStatusFilterOptions {
  All = 'all',
  AllButPublished = 'all_but_published',
  Published = 'published',
}

/* Recipe table */
export enum RecipeView {
  List = 'List',
  Grid = 'Grid',
}
export enum RecipeTableColumns {
  Id = 'id',
  Image = 'image',
  Name = 'title',
  OnBrand = 'onbrand',
  Type = 'type',
  Season = 'season',
  Performance = 'performance',
  Countries = 'countries',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Translation = 'translation',
}
export type RecipeTableHeader = TableHeader<RecipeTableColumns>;

/* Recipe creator */
export type RecipeFormMeasurement = {
  id: number | null;
  quantity: string;
  unit: string;
  recipeIngredientId: number | null;
};
export type RecipeFormIngredient = {
  isIngredientRecipe: boolean;
  name: string;
  refId: RecipeBase['id'] | Ingredient['id'] | null;
  measurements: RecipeFormMeasurement[];
  notes: string;
  type?: NewIngredientType;
  status?: RecipeStatus;
  country?: Country;
};
export enum NewIngredientType {
  Ingredient = 'ingredient',
  MEP = 'mep',
}
export type RecipeFormValues = Pick<
  RecipeDetailed,
  | 'description'
  | 'image'
  | 'batchImage'
  | 'seasons'
  | 'country'
  | 'preparationSteps'
  | 'comments'
  | 'performanceData'
> & {
  id: number;
  name: string;
  category: RecipeCategory | null;
  type: RecipeType | null;
  requirementType: RecipeRequirement | null;
  onBrandScore: number | null;
  status: RecipeStatus;
  ingredients: RecipeFormIngredient[];
  notes: Remark[];
  updatedAt?: string;
  translation?: Omit<RecipeTranslationsResponse, 'ingredients'> & {
    ingredients: TranslatedRecipeFormIngredient[];
    initialIngredients: TranslatedRecipeIngredient[];
  };
};

export type UpdateRecipeParams = Partial<
  Omit<
    RecipeDetailed,
    | 'id'
    | 'onBrand'
    | 'createdAt'
    | 'updatedAt'
    | 'onBrandErrors'
    | 'traceId'
    | 'translations'
    | 'performanceData'
  >
> & {
  id: number;
};
export type PostRecipeParams = Omit<UpdateRecipeParams, 'id'>;
export type UpdateRecipePerformanceDataParams = {
  recipeId: number;
  performanceData: RecipePerformanceData;
};

/* Recipe ingredients */
export type RecipeIngredient = Pick<
  RecipeFormIngredient,
  'isIngredientRecipe' | 'name' | 'notes'
> & {
  id: number | null;
  order: number;
  quantity: string;
  measurement: Measurement;
  ingredient?: { id: Ingredient['id'] | null; name?: Ingredient['name'] } | null;
  recipe?: {
    id: RecipeBase['id'];
    name?: Ingredient['name'];
    status: RecipeStatus;
    country: Country;
  } | null;
  notes?: string;
};
export type Ingredient = {
  id: number;
  name: string;
  notes: string;
};
export type GetIngredientOptions = {
  page?: number;
  page_size?: number;
  search?: string;
  sort?: string;
};
export type PostIngredientOptions = Pick<Ingredient, 'name' | 'notes'>;

export type RecipeCommentParams = {
  recipeId: number;
  message: string;
};

/* Recipe preparation steps */
export type PreparationStep = {
  id?: number;
  order?: number;
  description: string;
  image: string;
};

/* Recipe measurements */
export type Measurement = {
  id: number | null;
  unit: string;
};
export type GetMeasurementOptions = {
  page?: number;
  page_size?: number;
  search?: string;
  sort?: string;
};
export type PostMeasurementParams = {
  unit: string;
};

/* Recipe autocomplete */
export type RecipeAutocompleteOption = {
  name: RecipeBase['name'];
  id?: RecipeBase['id'];
  requirementType?: RecipeBase['requirementType'];
  country?: RecipeBase['country'];
  status?: RecipeBase['status'];
};

/* On brandness */
export enum RecipeOnBrandnessRules {
  IngredientCount = 'INGREDIENT_COUNT',
  MaterialCount = 'MATERIAL_COUNT',
  MepCount = 'MEP_COUNT',
  ForbiddenWords = 'FORBIDDEN_WORDS',
}
export type RecipeOnBrandnessRuleSet = {
  rule: RecipeOnBrandnessRules;
  properties: {
    // Ingredient count
    ingredientRule?: {
      deduction: number;
      rangeMin: number;
      rangeMax: number | 'Infinity';
    };
    // Material count
    forbiddenMaterials?: string[];
    allowedMaterialCount?: number;
    // Forbidden words
    forbiddenWords: string[];
    keywordDeduction: number;
    // MEP count
    mepRule: {
      deduction: number;
      rangeMin: number;
      rangeMax: number | 'Infinity';
    };
  };
};

export type ExportRecipeParams = {
  recipeId: number;
  emails: string[];
  language: string;
};

export type RecipeComplianceRuleSet = {
  keywords: string[];
  keywordDeduction: number;
  allowedMaterialCount: number;
  mepRules: {
    rangeMin: number;
    rangeMax: number;
    deduction: number;
  }[];
  materials: string[];
  materialDeduction: number;
  ingredientRules: {
    rangeMin: number;
    rangeMax: number | string;
    deduction: number;
  }[];
};
