import { ArrowForwardRounded as ArrowForwardRoundedIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Collapse, Rating, Stack } from '@mui/material';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Country } from 'constants/countries.constants';
import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { COUNTRIES_ALL } from 'constants/recipes.constants';
import { Routes } from 'constants/routes.constants';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { SaveOptions } from 'types/common.types';
import {
  RecipeTranslationMethod,
  RecipeTranslationsResponse,
  RecipeTranslationStatus,
  Translation,
} from 'types/recipe-translations.types';
import { RecipeFormValues, RecipeRequirement } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';
import { CountrySelector } from 'components/@common/CountrySelector';
import { Tooltip } from 'components/@common/Tooltip';
import { useIsInMenuItemDrawer } from 'components/@menu-form';
import { RecipeNameAutocomplete } from 'components/@recipe-form/RecipeNameAutocomplete';
import { TranslationField, TranslationStatus } from 'components/@recipe-translations';
import { TranslationSelector } from 'components/@recipe-translations/TranslationSelector';

import {
  RecipeActions,
  RecipeCategory,
  RecipeRequirementType,
  RecipeSeasons,
  RecipeType,
  RetryTranslationButton,
} from './components';
import { TitleBox } from './RecipeFormMainInfo.style';

type Props = {
  isReadOnly: boolean;
  isSaving: boolean;
  disableActions: boolean | undefined;
  onSave: (options: SaveOptions) => void;
  onEditRecipe: () => void;
  onStopEditRecipe: () => void;
  calculateOnBrandness: (successCallback?: () => void) => void;
  recipeTranslations?: Translation[];
  onEditTranslations: () => void;
  isEditingTranslations: boolean;
  isLoadingTranslations: boolean;
  targetTranslationLocale: string;
  isSubmitted?: boolean;
  isTranslating: boolean;
};

const RecipeFormMainInfo = ({
  isSaving,
  isReadOnly,
  disableActions,
  onSave,
  onEditRecipe,
  onStopEditRecipe,
  calculateOnBrandness,
  recipeTranslations = [],
  onEditTranslations,
  isEditingTranslations,
  isLoadingTranslations,
  targetTranslationLocale,
  isSubmitted,
  isTranslating,
}: Props) => {
  const { translationsFeature, recipeCrudFeature } = useFeatureFlags();
  const isInMenuItemDrawer = useIsInMenuItemDrawer();
  const { setValue } = useFormContext<RecipeFormValues>();
  const recipeId = useWatch<RecipeFormValues>({ name: 'id' }) as RecipeFormValues['id'];
  const country = useWatch<RecipeFormValues>({ name: 'country' }) as Country;
  const requirementType = useWatch<RecipeFormValues>({
    name: 'requirementType',
  }) as RecipeRequirement;
  const translation = useWatch<RecipeFormValues>({
    name: 'translation',
  }) as RecipeFormValues['translation'];

  const openRecipe = () => {
    window.open(generatePath(Routes.RecipeDetail, { recipeId: String(recipeId) }), '_blank');
  };

  const translationLocale = useWatch({
    name: 'translation.language',
  }) as RecipeTranslationsResponse['language'];

  const isShowingTranslation = targetTranslationLocale === translationLocale && !isInMenuItemDrawer;

  return (
    <Stack width="100%" height="fit-content">
      {isInMenuItemDrawer && (
        <Stack mb={3} direction="row" alignItems="center" justifyContent="space-between">
          <Rating />
          <CountryFlag
            country={requirementType === RecipeRequirement.Core ? COUNTRIES_ALL : country}
            size={32}
          />
        </Stack>
      )}

      <Collapse in={!isEditingTranslations}>
        <RecipeRequirementType isReadOnly={isReadOnly} />
      </Collapse>

      <Stack mb={4} gap={1}>
        <TitleBox>
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            flex={isReadOnly && !isEditingTranslations ? undefined : 1}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse',
                alignItems: 'start',
              },
            })}
          >
            {isEditingTranslations && (
              <TranslationField
                fieldName="translation.translation.name"
                initialValue={String(translation?.translation.name)}
                isTitle
                method={
                  translation?.translation.fieldTranslationMethods.name ||
                  RecipeTranslationMethod.AI
                }
                onChange={() =>
                  setValue(
                    'translation.translation.fieldTranslationMethods.name',
                    RecipeTranslationMethod.Human,
                  )
                }
              />
            )}

            {!isEditingTranslations && (
              <RecipeNameAutocomplete
                isReadOnly={isReadOnly}
                onSave={onSave}
                isEditingTranslations={isEditingTranslations}
                isShowingTranslations={isShowingTranslation}
                isLoadingTranslations={isLoadingTranslations}
              />
            )}
          </Stack>

          <Box display="flex" gap={1} alignItems="center">
            {!isInMenuItemDrawer && isReadOnly && recipeCrudFeature && translationsFeature && (
              <Tooltip
                title={<FormattedMessage id="recipes.translations.translate_on_submit.tooltip" />}
                maxWidth={200}
                disabled={isSubmitted || isEditingTranslations}
              >
                <TranslationSelector
                  isEditingTranslations={isEditingTranslations}
                  prefix={
                    isSubmitted &&
                    recipeTranslations.some(
                      (t) => t.status === RecipeTranslationStatus.Pending,
                    ) && <CircularProgress size={16} color="inherit" sx={{ mt: 0.25 }} />
                  }
                  menuItemSuffix={(language, status) => (
                    <Box display="flex" alignItems="center" gap={1}>
                      {status === RecipeTranslationStatus.Error && (
                        <RetryTranslationButton language={language} recipeId={recipeId} />
                      )}
                      <TranslationStatus verifiedStatus={status} />
                    </Box>
                  )}
                  suffix={(lang, status) => {
                    if (!!recipeTranslations.length && lang !== DEFAULT_TRANSLATION_LOCALE) {
                      return <TranslationStatus verifiedStatus={status} />;
                    }
                  }}
                  recipeTranslations={recipeTranslations}
                  disabled={!recipeTranslations.length || !isSubmitted}
                  disableOptions
                />
              </Tooltip>
            )}

            {!disableActions && (
              <RecipeActions
                recipeTranslations={recipeTranslations}
                isReadOnly={isReadOnly}
                isSaving={isSaving}
                onStopEditRecipe={onStopEditRecipe}
                onEditRecipe={onEditRecipe}
                saveRecipe={onSave}
                calculateOnBrandness={calculateOnBrandness}
                isEditingTranslations={isEditingTranslations}
                onEditTranslations={onEditTranslations}
                targetTranslationLocale={targetTranslationLocale}
                isTranslating={isTranslating}
              />
            )}
          </Box>
        </TitleBox>

        {!isEditingTranslations && (
          <CountrySelector
            country={requirementType === RecipeRequirement.Core ? COUNTRIES_ALL : country}
            isReadOnly={requirementType === RecipeRequirement.Core || isReadOnly}
          />
        )}
      </Stack>

      <Collapse in={!isEditingTranslations}>
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap" mt={0.5}>
          <RecipeSeasons isReadOnly={isReadOnly} />
          <RecipeCategory isReadOnly={isReadOnly} />
          <RecipeType isReadOnly={isReadOnly} />

          {isInMenuItemDrawer && (
            <Box ml="auto">
              <Button
                endIcon={<ArrowForwardRoundedIcon />}
                variant="text"
                sx={(theme) => ({ color: theme.palette.global01.main })}
                onClick={openRecipe}
              >
                <FormattedMessage id="recipes.view_full" />
              </Button>
            </Box>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default memo(RecipeFormMainInfo);
