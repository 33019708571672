import { Country } from 'constants/countries.constants';

import { FiltersObject, Season, TableHeader } from './common.types';
import { PdfFile } from './media.types';
import { RecipeDetailed, RecipePreview } from './recipes.types';

export enum MenuStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Future = 'FUTURE',
}
export enum MenuProcessingStatus {
  Processing = 'PROCESSING',
  Done = 'DONE',
  Error = 'ERROR',
  PdfError = 'PDF_ERROR',
  PartialError = 'PARTIAL_ERROR', // currently not caught because there's no way to hide any alert we would show
}
export type Menu = {
  id: number;
  name: string;
  description?: string;
  status: MenuStatus;
  seasons: Season[];
  year: number;
  isApproved: boolean;
  coveragePercentage: number;
  complianceScore: number | null;
  complianceErrors?: MenuComplianceError[];
  country: Country;
  menuItems: MenuItem[];
  pdfFile: PdfFile;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  processingStatus: MenuProcessingStatus;
};

export enum MenuItemStatus {
  Approved = 'APPROVED',
  Matched = 'MATCHED',
  Unmatched = 'UNMATCHED',
  ManualOverride = 'MANUAL_OVERRIDE',
}

export type MenuItemRecipe = Partial<Omit<RecipePreview, 'id'>> &
  Pick<RecipePreview, 'id'> &
  Pick<RecipeDetailed, 'translations'>;

export type MenuItem = {
  id: number;
  menuId: number;
  name: string;
  description: string;
  section: string;
  status: MenuItemStatus;
  isPurchasedItem: boolean;
  purchasedItem: MenuPurchasedItem | { id: null | number };
  recipe: MenuItemRecipe;
  traceId?: string;
  updatedAt: string | null;
};

export type MenuPurchasedItem = {
  id: number | null;
  name: string;
  description: string;
  pdf_file: string | null;
};

/* Api */
export type GetMenuParams = {
  page?: number;
  page_size?: number;
  search?: string;
  country?: Country[];
  season?: Season[];
  sort?: string;
  year?: number[];
  status?: MenuStatus[];
};
export type PostMenuParams = {
  name?: string | null;
  description?: string;
  pdf_file: string;
  seasons?: Season[];
};
export type UpdateMenuParams = {
  id: Menu['id'];
  name: string;
  description?: string;
  status: MenuStatus;
  seasons?: Season[];
  country: Country;
  year: number;
  pdfFile?: PdfFile;
};
/* Menu item api */
export type GetMenuItemRecommendedRecipeParams = {
  menuId: Menu['id'];
  menuItemId: MenuItem['id'];
};
export type PostMenuItemParams = {
  menuId: Menu['id'];
  menuItem?: Partial<Omit<MenuItem, 'id' | 'traceId' | 'recipe'>> & {
    id?: MenuItem['id'] | null;
    name?: string;
    recipe?: { id: RecipePreview['id'] | null } | RecipePreview;
  };
};
export type DeleteMenuItemParams = {
  id: MenuItem['id'];
  menuId: Menu['id'];
};

/* Menu Filters */
export enum MenuFilters {
  country = 'country',
  season = 'season',
  year = 'year',
  status = 'status',
}
export type MenuFiltersObject = FiltersObject<MenuFilters>;

export enum MenuItemFilterOptions {
  Matched = 'matched',
  Unmatched = 'unmatched',
  Purchased = 'purchased',
}

/* Menu Table */
export enum MenuTableColumns {
  Id = 'id',
  Title = 'title',
  Country = 'country',
  Year = 'year',
  Season = 'season',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Coverage = 'coveragePercentage',
}
export type MenuTableHeader = TableHeader<MenuTableColumns>;
export type MenuFormData = Pick<Menu, 'id' | 'name' | 'country' | 'seasons' | 'year' | 'status'>;

/* Menu compliance */
export enum MenuComplianceRules {
  GlobalDishRatio = 'GLOBAL_DISH_RATIO',
  MaxItemLimit = 'MAX_ITEM_LIMIT',
}
export type MenuComplianceError = {
  rule: MenuComplianceRules;
  properties: Record<string, string>;
};

export type MenuComplianceRuleSet = {
  maxItems: number;
  maxItemDeduction: number;
  globalDishRules: {
    score: number;
    rangeMin: number;
    rangeMax: number;
  }[];
};

export type ExportMenuParams = {
  menuId: number;
  emails: string[];
  languages: { language: string }[];
};
