import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { TranslateRecipeOptions } from 'types/recipe-translations.types';

const translateRecipe = (options: TranslateRecipeOptions) => {
  return apiService.translateRecipe(options);
};
export const useTranslateRecipe = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: translateRecipe,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });
    },
    onError: () => {
      notifications.error({
        message: intl.formatMessage({ id: 'recipes.translations.automatic.error' }),
      });
    },
  });
  return {
    translateRecipe: mutate,
    isPending,
    isError,
    error,
  };
};
