import { Collapse, Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { StorageMediaContext } from 'types/media.types';
import { RecipeTranslationsResponse } from 'types/recipe-translations.types';
import { RecipeFormValues } from 'types/recipes.types';

import { useIsInMenuItemDrawer } from 'components/@menu-form';

import { RecipeFormDropzone } from '../RecipeFormDropzone';
import { RecipeFormIngredients } from '../RecipeFormIngredients';
import { RecipeFormPreparation } from '../RecipeFormPreparation';
import { RecipeFormRemarks } from '../RecipeFormRemarks';

type Props = {
  initialValues: RecipeFormValues;
  isReadOnly: boolean;
  isEditingTranslations: boolean;
  targetTranslationLocale: string;
  isLoadingTranslations: boolean;
};

const RecipeFormItems = ({
  isReadOnly,
  initialValues,
  isEditingTranslations,
  targetTranslationLocale,
  isLoadingTranslations,
}: Props) => {
  const isInMenuItemDrawer = useIsInMenuItemDrawer();

  const translationLocale = useWatch({
    name: 'translation.language',
  }) as RecipeTranslationsResponse['language'];

  const isShowingTranslation = targetTranslationLocale === translationLocale && !isInMenuItemDrawer;

  return (
    <Stack gap={3} mt={3}>
      <RecipeFormIngredients
        isReadOnly={isReadOnly}
        isEditingTranslations={isEditingTranslations}
        targetTranslationLocale={targetTranslationLocale}
        isLoadingTranslations={isLoadingTranslations}
        isShowingTranslation={isShowingTranslation}
      />

      <Collapse in={!isEditingTranslations}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ '@media print': { flexDirection: 'row', pageBreakInside: 'avoid' } }}
          gap={3}
        >
          <RecipeFormDropzone
            title={<FormattedMessage id="recipes.batch.label" />}
            name="image"
            storageContext={StorageMediaContext.RecipeStep}
            recipeId={`${initialValues.id}`}
            isReadOnly={isReadOnly}
          />
          <RecipeFormDropzone
            title={<FormattedMessage id="recipes.plating.label" />}
            name="batchImage"
            storageContext={StorageMediaContext.RecipeStep}
            recipeId={`${initialValues.id}`}
            isReadOnly={isReadOnly}
          />
        </Stack>
      </Collapse>

      <RecipeFormPreparation
        recipeId={initialValues.id}
        isReadOnly={isReadOnly}
        isEditingTranslations={isEditingTranslations}
        targetTranslationLocale={targetTranslationLocale}
        isLoadingTranslations={isLoadingTranslations}
        isShowingTranslation={isShowingTranslation}
      />

      <RecipeFormRemarks
        isReadOnly={isReadOnly}
        isEditingTranslations={isEditingTranslations}
        targetTranslationLocale={targetTranslationLocale}
        isLoadingTranslations={isLoadingTranslations}
        isShowingTranslation={isShowingTranslation}
      />
    </Stack>
  );
};

export default RecipeFormItems;
