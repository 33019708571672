import { useCallback, useMemo } from 'react';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { useSortLanguages } from 'hooks';
import { useLanguages } from 'queries';
import { MenuItem } from 'types/menus.types';
import {
  CountedTranslation,
  RecipeTranslationMethod,
  RecipeTranslationStatus,
  TranslatedIngredient,
  TranslatedRecipeFormIngredient,
  TranslatedRecipeIngredient,
} from 'types/recipe-translations.types';

export const getLanguageKey = (language?: string) =>
  `language.${language || DEFAULT_TRANSLATION_LOCALE}` as I18nKey;

/**
 * Hook to get the verified recipe percentage of each language in a menu
 * @param menuItems - All menu items in the menu
 * @returns An array of translations with the verified percentage of each language
 */
export const useVerifiedPercentagesMap = (menuItems: MenuItem[]): CountedTranslation[] => {
  const { languages } = useLanguages();
  const menuRecipes = menuItems.flatMap((menuItem) => (menuItem.recipe ? [menuItem.recipe] : []));

  const languageStatusCounts = languages.reduce(
    (acc, language) => {
      acc[language] = { verifiedCount: 0, translatedCount: 0 };
      return acc;
    },
    {} as Record<string, { verifiedCount: number; translatedCount: number }>,
  );

  // Update counts for each recipe translation
  menuRecipes.forEach((recipe) => {
    recipe.translations?.forEach((translation) => {
      const { language, status } = translation;
      if (languages.includes(language)) {
        languageStatusCounts[language].translatedCount += 1;
        if (status === RecipeTranslationStatus.Verified) {
          languageStatusCounts[language].verifiedCount += 1;
        }
      }
    });
  });

  // Calculate the verified percentage and translations
  const translations = languages.map((language) => {
    const countData = languageStatusCounts[language];
    const verifiedPercentage =
      countData.translatedCount > 0
        ? Math.round((countData.verifiedCount / menuRecipes.length) * 100)
        : 0;

    return {
      language,
      totalCount: menuRecipes.length,
      verifiedCount: countData.verifiedCount,
      translatedCount: countData.translatedCount,
      status: verifiedPercentage ? RecipeTranslationStatus.Verified : RecipeTranslationStatus.AI,
      verifiedPercentage,
    };
  });

  return useSortLanguages(translations);
};

/**
 * Hook to format recipe translations for the form
 * - We need this formatter because the api response returns each ingredient twice
 * (One for each measurement)
 */
export const useTransformIngredientTranslations = () => {
  const transformApiIngredientTranslationsToForm = useCallback(
    (ingredients: TranslatedRecipeIngredient[]): TranslatedRecipeFormIngredient[] => {
      return ingredients.reduce<TranslatedRecipeFormIngredient[]>((acc, ingredient) => {
        const isRecipe = !!ingredient.isIngredientRecipe;

        const translatedName = String(
          isRecipe
            ? (ingredient.recipe?.translation.name ?? ingredient.recipe?.name)
            : (ingredient.ingredient?.translation.name ?? ingredient.ingredient?.name),
        );

        // Get peer ingredient to get other measurement if available
        const peerIngredient = ingredients
          .filter((peer) => peer.id !== ingredient.id)
          .find((peer) =>
            isRecipe
              ? peer.recipe?.id === ingredient.recipe?.id
              : peer.ingredient?.id === ingredient.ingredient?.id,
          );

        const transformedIngredient: TranslatedRecipeFormIngredient = {
          isIngredientRecipe: isRecipe,
          refId: Number(isRecipe ? ingredient.recipe?.id : ingredient.ingredient?.id),
          name: (isRecipe ? ingredient.recipe?.name : ingredient.ingredient?.name) || '',
          translatedName,
          translationId: Number(
            isRecipe ? ingredient.recipe?.translation.id : ingredient.ingredient?.translation.id,
          ),
          measurements: [ingredient.measurement, peerIngredient?.measurement].filter((m) => !!m),
          note: {
            note: ingredient.notes,
            method: ingredient.translation.fieldTranslationMethods.notes,
            translatedNote: ingredient.translation.notes || '',
          },
          method: isRecipe
            ? RecipeTranslationMethod.MEP
            : (ingredient.ingredient?.translation?.fieldTranslationMethods?.name ??
              RecipeTranslationMethod.AI),
        };

        // Check for uniqueness before adding to the accumulator
        const isUnique = !acc.some(
          (existingIngredient) =>
            existingIngredient.translationId === transformedIngredient.translationId &&
            existingIngredient.isIngredientRecipe === transformedIngredient.isIngredientRecipe,
        );

        if (isUnique) {
          acc.push(transformedIngredient);
        }

        return acc;
      }, []);
    },
    [],
  );

  const transformFormIngredientTranslationsToApi = useCallback(
    (
      ingredients: TranslatedRecipeFormIngredient[],
      initialIngredients: TranslatedRecipeIngredient[],
    ): TranslatedRecipeIngredient[] => {
      return initialIngredients.reduce<TranslatedRecipeIngredient[]>((acc, ingredient) => {
        const isRecipe = !!ingredient.isIngredientRecipe;
        const ingredientData = ingredients.find((i) =>
          isRecipe
            ? i.translationId === ingredient.recipe?.translation.id
            : i.translationId === ingredient.ingredient?.translation.id,
        );
        const transformedIngredient: TranslatedRecipeIngredient = {
          ...ingredient,
          recipe: isRecipe
            ? transformTranslatedIngredientToApi(ingredient.recipe, ingredientData)
            : null,
          ingredient: isRecipe
            ? null
            : transformTranslatedIngredientToApi(ingredient.ingredient, ingredientData),
          notes: ingredientData?.note.note || ingredient.notes,
          translation: {
            ...ingredient.translation,
            notes: ingredientData?.note.translatedNote || ingredient.translation.notes,
            fieldTranslationMethods: {
              ...ingredient.translation.fieldTranslationMethods,
              notes: ingredientData?.note.method ?? RecipeTranslationMethod.AI,
            },
          },
        };

        acc.push(transformedIngredient);
        return acc;
      }, []);
    },
    [],
  );

  const returnValue = useMemo(
    () => ({ transformApiIngredientTranslationsToForm, transformFormIngredientTranslationsToApi }),
    [transformApiIngredientTranslationsToForm, transformFormIngredientTranslationsToApi],
  );
  return returnValue;
};

const transformTranslatedIngredientToApi = (
  ingredient: TranslatedIngredient | null,
  initialIngredient?: TranslatedRecipeFormIngredient,
) => {
  if (!ingredient?.translation) {
    return ingredient;
  }
  return {
    ...ingredient,
    translation: {
      ...ingredient?.translation,
      fieldTranslationMethods: {
        ...ingredient?.translation.fieldTranslationMethods,
        name: initialIngredient?.method || RecipeTranslationMethod.AI,
      },
      name: String(initialIngredient?.translatedName),
    },
  };
};
