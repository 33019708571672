import {
  autocompleteClasses,
  AutocompleteRenderInputParams,
  filledInputClasses,
  InputAdornment,
  inputBaseClasses,
  TextField,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { MEP_PREFIX } from 'constants/recipes.constants';

type Props = AutocompleteRenderInputParams & {
  isMEP: boolean;
};

const RecipeNameField = ({ isMEP, ...props }: Props) => {
  const intl = useIntl();
  return (
    <TextField
      className="recipe-title"
      variant="filled"
      {...props}
      slotProps={{
        htmlInput: {
          ...props.inputProps,
          maxLength: 255,
          style: { textTransform: 'uppercase', padding: 0, height: 52 },
        },
        input: {
          ...props.InputProps,
          startAdornment: isMEP && (
            <InputAdornment position="start" sx={{ marginTop: '0 !important' }}>
              <Typography fontSize={32}>{MEP_PREFIX}</Typography>
            </InputAdornment>
          ),
        },
      }}
      sx={{
        [`& .${inputBaseClasses.root}`]: {
          paddingTop: 0,
          ':hover': { backgroundColor: 'black.4' },
        },
        '@media print': {
          [`& .${filledInputClasses.root}`]: { p: 0 },
          [`& .${autocompleteClasses.input}`]: {
            textOverflow: 'unset',
            whiteSpace: 'normal',
          },
        },
      }}
      placeholder={intl.formatMessage({ id: 'recipes.creator.input.title.label' })}
    />
  );
};

export default RecipeNameField;
