import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { RecipeTranslationStatus } from 'types/recipe-translations.types';
import { RecipeDetailed, RecipeStatus } from 'types/recipes.types';

const fetchRecipe = async (recipeId: number) => {
  const { data: recipe } = await apiService.getRecipe(recipeId);
  return recipe;
};

export const useRecipeById = (
  recipeId: number,
  config: CommonQueryOptions<
    ApiResponse<RecipeDetailed>,
    ReturnType<typeof QueryKeys.recipes.byId>
  > = {},
) => {
  const { data, status, isLoading, isFetching, error, isError, refetch } = useQuery({
    queryKey: QueryKeys.recipes.byId(recipeId),
    queryFn: () => fetchRecipe(recipeId),
    refetchInterval: ({ state: { data } }) => {
      const recipe = data?.data;
      if (!recipe) return false;

      const isDraft = [RecipeStatus.Draft, RecipeStatus.Rejected].includes(recipe.status);
      const isTranslating = recipe.translations?.some(
        (translation) => translation.status === RecipeTranslationStatus.Pending,
      );

      return isTranslating && !isDraft ? 5000 : false;
    },
    ...config,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  return {
    data: data?.data,
    status,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
};
