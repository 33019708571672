import { Close as CloseIcon } from '@mui/icons-material';
import { Drawer, IconButton, Stack, useTheme } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCreateRecipe } from 'queries';
import { useUpdateMenuItem } from 'queries/menus/useUpdateMenuItem';
import { useNotifications } from 'services/snackbar';
import { MenuItem, MenuItemStatus } from 'types/menus.types';
import { RecipePreview, RecipeType } from 'types/recipes.types';

import { MenuItemDrawerContext } from 'components/@menu-form/MenuItemDrawer/MenuItemDrawerContext';

import MenuItemNoResults from './MenuItemNoResults';
import MenuItemPurchased from './MenuItemPurchased';
import MenuItemRecipe from './MenuItemRecipe';
import MenuItemRecipeList from './MenuItemRecipeList';

interface Props {
  open: boolean;
  menuItem: MenuItem | undefined;
  disabled: boolean;
  recalculateCompliance: () => void;
  onClose: () => void;
}

const MenuItemDrawer = ({ menuItem, open, disabled, recalculateCompliance, onClose }: Props) => {
  const { menuCrudFeature } = useFeatureFlags();
  const [isPickingRecipe, setIsPickingRecipe] = useState(false);
  const [isCreatingRecipe, setIsCreatingRecipe] = useState(false);
  const [isCreatingPurchasedItem, setIsCreatingPurchasedItem] = useState(false);
  const posthog = usePostHog();
  const theme = useTheme();
  const { lg } = useMedia();

  // push the rest of the screen to the left to give more space for the menu, so the drawer overlaps as little as possible
  const previousOpen = useRef(open);
  useEffect(() => {
    if (!lg) return;

    const main = document.querySelector('main');
    if (!main) return console.error("MenuItemDrawer: couldn't find main element");

    const transition = 'all 0.5s ease-in-out';
    const marginLeft = `calc((100vw - ${theme.mixins.sidebarWidth}px - ${theme.spacing(6)} - 1188px) / 2)`;
    let timeout1: NodeJS.Timeout, timeout2: NodeJS.Timeout, timeout3: NodeJS.Timeout;

    if (open) {
      main.style.transition = transition;
      main.style.marginLeft = marginLeft;
      timeout1 = setTimeout(() => {
        main.style.marginLeft = '0px';
      });
    } else if (previousOpen.current) {
      main.style.transition = transition;
      main.style.marginLeft = '0px';
      timeout2 = setTimeout(() => {
        main.style.marginLeft = marginLeft;
      });

      timeout3 = setTimeout(() => {
        main.style.transition = '';
        main.style.marginLeft = '';
      }, 500);
    }

    previousOpen.current = open;

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
      main.style.transition = '';
      main.style.marginLeft = '';
    };
  }, [theme, open, lg]);

  useEffect(() => {
    // Make sure to reset all state when closing the drawer or switching menuItem
    setIsPickingRecipe(false);
    setIsCreatingRecipe(false);
    setIsCreatingPurchasedItem(false);
  }, [open, menuItem]);

  const isNoResults = !isPickingRecipe && !menuItem?.isPurchasedItem && !menuItem?.recipe?.id;
  const isRecipe = !isPickingRecipe && !!menuItem?.recipe?.id;
  const isPurchasedItem = !isPickingRecipe && !!menuItem?.isPurchasedItem;

  const intl = useIntl();
  const navigate = useNavigate();
  const notifications = useNotifications();

  const { createRecipe } = useCreateRecipe();
  const { updateMenuItem, isPending: isUpdatingMenuItem } = useUpdateMenuItem();

  const handleSearchRecipe = () => {
    if (!menuItem) return;
    setIsPickingRecipe(true);
  };

  const handleSelectRecipe = (
    recipe: RecipePreview,
    { silent }: { silent?: boolean } = { silent: false },
  ) => {
    if (!menuItem || isUpdatingMenuItem || !menuCrudFeature) return;

    updateMenuItem(
      {
        ...menuItem,
        name:
          !menuItem.name ||
          menuItem.name === intl.formatMessage({ id: 'menus.items.label.untitled' })
            ? recipe.name
            : menuItem.name,
        id: menuItem.id,
        menuId: menuItem?.menuId,
        isPurchasedItem: false,
        recipe,
        status: MenuItemStatus.ManualOverride,
        silent,
      },
      { onSuccess: recalculateCompliance },
    );
  };

  const handleCreateRecipe = () => {
    if (!menuItem || !menuCrudFeature) return;
    setIsCreatingRecipe(true);
    posthog.capture('create_recipe', { type: 'new', source: 'menu' });
    createRecipe(
      { name: menuItem.name, type: RecipeType.Recipe },
      {
        onSuccess: ({ data: recipe }) => {
          updateMenuItem(
            {
              ...menuItem,
              menuId: menuItem?.menuId,
              id: menuItem.id,
              isPurchasedItem: false,
              recipe,
              status: MenuItemStatus.ManualOverride,
            },
            {
              onSuccess: () => {
                recalculateCompliance();

                notifications.success({
                  message: intl.formatMessage({ id: 'recipes.recipe.added' }),
                  cta: intl.formatMessage({ id: 'recipes.recipe.added.view' }),
                  onClick: () =>
                    navigate(generatePath(Routes.RecipeDetail, { recipeId: String(recipe.id) })),
                });
              },
              onSettled: () => setIsCreatingRecipe(false),
            },
          );
        },
        onError: () => setIsCreatingRecipe(false),
      },
    );
  };

  const handlePurchasedItem = () => {
    if (!menuItem || !menuCrudFeature) return;
    setIsCreatingPurchasedItem(true);
    updateMenuItem(
      {
        ...menuItem,
        menuId: menuItem?.menuId,
        id: menuItem.id,
        isPurchasedItem: true,
        status: MenuItemStatus.ManualOverride,
        recipe: { id: null },
      },
      {
        onSuccess: () => {
          setIsPickingRecipe(false);
          recalculateCompliance();
        },
        onSettled: () => setIsCreatingPurchasedItem(false),
      },
    );
  };

  const handleUnlinkMenuItem = () => {
    if (!menuItem || !menuCrudFeature) return;
    updateMenuItem(
      {
        ...menuItem,
        menuId: menuItem?.menuId,
        id: menuItem.id,
        isPurchasedItem: false,
        recipe: { id: null },
        status: MenuItemStatus.Unmatched,
      },
      { onSuccess: recalculateCompliance },
    );
  };

  const scrollerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollerRef.current?.scrollTo({ top: 0, behavior: 'instant' });
  }, [menuItem?.id]);

  useEffect(() => {
    const escapeListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    if (open) {
      window.addEventListener('keydown', escapeListener);
    }
    return () => window.removeEventListener('keydown', escapeListener);
  }, [open, onClose]);

  return (
    <MenuItemDrawerContext.Provider value>
      {/* 👆 just to allow the recipe to check whether it's being displayed in the drawer or not */}
      <Drawer
        variant="persistent"
        anchor="right"
        open={menuItem && open}
        hideBackdrop
        PaperProps={{ ref: scrollerRef, sx: { overflowX: 'hidden' } }}
        onClose={onClose}
      >
        <IconButton
          sx={{
            position: 'fixed',
            top: 16,
            right: 16,
            zIndex: 1,
            ml: 'auto',
            bgcolor: 'grey.200',
            '&:hover': { bgcolor: 'grey.300' },
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ width: 28, height: 28, color: 'global01.main' }} />
        </IconButton>

        <Stack width="532px" display="flex" justifyContent="center" alignItems="center" my="auto">
          {isPickingRecipe && menuCrudFeature && (
            <MenuItemRecipeList
              menuId={menuItem?.menuId}
              menuItemId={menuItem?.id}
              isCreatingRecipe={isCreatingRecipe}
              isCreatingPurchasedItem={isCreatingPurchasedItem}
              isUpdatingMenuItem={isUpdatingMenuItem}
              handleCreateRecipe={handleCreateRecipe}
              handlePurchasedItem={handlePurchasedItem}
              handleSelectRecipe={handleSelectRecipe}
            />
          )}
          {isNoResults && (
            <MenuItemNoResults
              isCreatingRecipe={isCreatingRecipe}
              isCreatingPurchasedItem={isCreatingPurchasedItem}
              isUpdatingMenuItem={isUpdatingMenuItem}
              handleSearchRecipe={handleSearchRecipe}
              handleCreateRecipe={handleCreateRecipe}
              handlePurchasedItem={handlePurchasedItem}
            />
          )}
          {isRecipe && (
            <MenuItemRecipe
              menuItem={menuItem}
              disabled={disabled}
              isUpdatingMenuItem={isUpdatingMenuItem}
              isCreatingRecipe={isCreatingRecipe}
              isCreatingPurchasedItem={isCreatingPurchasedItem}
              handleSearchRecipe={handleSearchRecipe}
              handleCreateRecipe={handleCreateRecipe}
              handlePurchasedItem={handlePurchasedItem}
              setIsPickingRecipe={setIsPickingRecipe}
              handleUnlinkRecipe={handleUnlinkMenuItem}
            />
          )}
          {isPurchasedItem && (
            <MenuItemPurchased
              setIsPickingRecipe={setIsPickingRecipe}
              handleUnlink={handleUnlinkMenuItem}
            />
          )}
        </Stack>
      </Drawer>
    </MenuItemDrawerContext.Provider>
  );
};

export default MenuItemDrawer;
