import { Add as AddIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { domAnimation, LazyMotion } from 'framer-motion';
import { uniq } from 'lodash';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useScrollNavigation, useSearchState } from 'hooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCreateMenuItem, useDeleteMenuItem } from 'queries';
import { MenuItem, MenuItemFilterOptions, MenuItemStatus } from 'types/menus.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';
import { CoverageValues } from 'components/@menu-form/MenuForm/types';
import { MenuItemDrawer } from 'components/@menu-form/MenuItemDrawer';

import MenuItemActionBar from './MenuItemActionBar';
import MenuItemFilters from './MenuItemFilters';
import MenuItemsActions from './MenuItemsActions';
import MenuItemSection from './MenuItemSection';
import MenuItemSectionsOutline from './MenuItemSectionsOutline';

type Props = {
  menuId: number;
  menuItems: MenuItem[];
  coverageValues: CoverageValues;
  disabled: boolean;
  recalculateCompliance: () => void;
};

const MenuItems = ({
  menuId,
  coverageValues,
  menuItems,
  disabled,
  recalculateCompliance,
}: Props) => {
  const { menuCrudFeature } = useFeatureFlags();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMenuItems, setSelectedMenuItems] = useState<MenuItem[]>([]);

  // This way we always use the correct menuItem from cache, and not a stale one
  const [selectedMenuItemId, setSelectedMenuItemId] = useState<MenuItem['id']>();
  const selectedMenuItem = useMemo(
    () => menuItems.find((menuItem) => menuItem.id === selectedMenuItemId),
    [menuItems, selectedMenuItemId],
  );

  const sortedMenuItems = useMemo(() => menuItems.sort((a, b) => a.id - b.id), [menuItems]); // we don't want updates to re-sort the menuItems

  const intl = useIntl();

  const [searchEnabled, setSearchEnabled] = useState(false);
  const { searchValue, debouncedSearchValue, handleSearchChange, clearSearchValue } =
    useSearchState();

  const [menuItemFilters, setMenuItemFilters] = useState<{
    [key in MenuItemFilterOptions]: boolean;
  }>({
    [MenuItemFilterOptions.Matched]: false,
    [MenuItemFilterOptions.Unmatched]: false,
    [MenuItemFilterOptions.Purchased]: false,
  });

  const handleToggleFilter = useCallback((filter: MenuItemFilterOptions) => {
    setMenuItemFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  }, []);

  const handleMenuItemClick = useCallback(
    (menuItem: MenuItem) => {
      setSelectedMenuItemId(menuItem.id);
      setIsDrawerOpen(true);
    },
    [setSelectedMenuItemId, setIsDrawerOpen],
  );

  const { createMenuItem, isPending: isCreatingMenuItem } = useCreateMenuItem();
  const handleAddMenuItem = () => {
    if (!menuCrudFeature) return;
    createMenuItem(
      { menuId: menuId },
      {
        onSuccess: ({ data }) => {
          handleMenuItemClick(data);

          clearSearchValue();
          setSearchEnabled(false);
          recalculateCompliance();
        },
      },
    );
  };

  const handleAddNamedMenuItem = () => {
    if (!menuCrudFeature) return;
    createMenuItem({ menuId, menuItem: { name: debouncedSearchValue } });
  };

  const menuItemSections = useMemo(
    () =>
      uniq(
        sortedMenuItems
          .map((menuItem) => {
            if (!menuItem.section) return intl.formatMessage({ id: 'menus.section.none' });
            return menuItem.section;
          })
          .sort((a, b) => a.localeCompare(b))
          .sort((a, b) => {
            if (a === intl.formatMessage({ id: 'menus.section.none' })) return -1;
            if (b === intl.formatMessage({ id: 'menus.section.none' })) return 1;
            return 0;
          }),
      ),
    [intl, sortedMenuItems],
  );
  const categorizedMenuItems = useMemo(() => {
    return menuItemSections.reduce<{ section: string; menuItems: MenuItem[] }[]>(
      // Filter items while grouping them by section
      (acc, section) => [
        ...acc,
        {
          section: section,
          menuItems: menuItems
            .filter((menuItem) => {
              return (
                menuItem.section === section ||
                (section === intl.formatMessage({ id: 'menus.section.none' }) && !menuItem.section)
              );
            })
            .filter((menuItem) => {
              const { matched, unmatched, purchased } = menuItemFilters;
              if (!matched && !unmatched && !purchased) return true;
              if (
                matched &&
                !menuItem.isPurchasedItem &&
                [
                  MenuItemStatus.Matched,
                  MenuItemStatus.ManualOverride,
                  MenuItemStatus.Approved,
                ].includes(menuItem.status)
              )
                return true;
              if (unmatched && menuItem.status === MenuItemStatus.Unmatched) return true;
              if (purchased && menuItem.isPurchasedItem) return true;
              return false;
            })
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
      ],
      [],
    );
  }, [intl, menuItemFilters, menuItemSections, menuItems]);

  const filteredSections = useMemo(() => {
    if (!debouncedSearchValue)
      return categorizedMenuItems.filter((section) => !!section.menuItems.length);

    return categorizedMenuItems
      .map((section) => ({
        ...section,
        menuItems: section.menuItems.filter((menuItem) =>
          menuItem.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
        ),
      }))
      .filter((section) => !!section.menuItems.length);
  }, [debouncedSearchValue, categorizedMenuItems]);

  const menuItemsParentRef = useRef<HTMLDivElement>(null);
  const sectionNames = useMemo(
    () => filteredSections.map(({ section }) => section),
    [filteredSections],
  );
  const { activeSection, scrollToSection } = useScrollNavigation({
    sections: sectionNames,
    parentRef: menuItemsParentRef,
  });

  const { deleteMenuItem } = useDeleteMenuItem();

  // this is outside of the MenuItemRow and MenuItemSection components because otherwise the component might be removed by the optimistic update, and the onSuccess callback might fail to fire
  const handleRemoveMenuItem = useCallback(
    (menuItemId: number) => {
      deleteMenuItem({ id: menuItemId, menuId }, { onSuccess: recalculateCompliance });
    },
    [deleteMenuItem, recalculateCompliance, menuId],
  );

  return (
    <Stack sx={{ '@media print': { pageBreakInside: 'avoid' } }}>
      <Box gap={3} display="grid" gridTemplateColumns="repeat(4, 1fr)" gridTemplateRows="1fr">
        <MenuItemSectionsOutline
          onSectionClick={scrollToSection}
          activeSection={activeSection}
          categorizedMenuItems={filteredSections}
        />

        <ErrorBoundary boundary="menu-items-list">
          <Stack
            ref={menuItemsParentRef}
            bgcolor="bg.paper"
            borderRadius={(theme) => theme.mixins.borderRadius.base}
            overflow="hidden"
            gridArea="1/2/6/5"
            sx={(theme) => ({ [theme.breakpoints.down('sm')]: { gridArea: '1/1/2/6' } })}
          >
            <Stack
              direction="row"
              height={56}
              justifyContent="space-between"
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column-reverse',
                  height: 'auto',
                },
              })}
              borderBottom="1px solid"
              borderColor="divider"
            >
              <MenuItemFilters
                coverageValues={coverageValues}
                menuItemFilters={menuItemFilters}
                onToggleFilter={handleToggleFilter}
              />
              <MenuItemsActions
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                onClearSearch={clearSearchValue}
                searchEnabled={searchEnabled}
                setSearchEnabled={setSearchEnabled}
                onCreateMenuItem={handleAddMenuItem}
                isCreatingMenuItem={isCreatingMenuItem}
                disabled={disabled}
              />
            </Stack>
            <LazyMotion features={domAnimation} strict>
              {!filteredSections.length && !debouncedSearchValue && (
                <Box p={3} width="100%">
                  <Typography align="center" variant="subtitle1">
                    <FormattedMessage id="menus.items.none_found" />
                  </Typography>
                </Box>
              )}
              {!disabled &&
                !filteredSections.length &&
                !!debouncedSearchValue &&
                menuCrudFeature && (
                  <Stack alignItems="center" p={3} width="100%">
                    <LoadingButton
                      loading={isCreatingMenuItem}
                      onClick={handleAddNamedMenuItem}
                      variant="contained"
                      color="primary"
                      sx={(theme) => ({
                        maxHeight: '100%',
                        ...theme.typography.buttonSmall,
                        textTransform: 'none',
                      })}
                    >
                      <AddIcon sx={{ width: 16 }} />
                      <FormattedMessage
                        id="menus.items.add_name"
                        values={{ name: debouncedSearchValue }}
                      />
                    </LoadingButton>
                  </Stack>
                )}
              {!!filteredSections.length &&
                filteredSections.map(({ section, menuItems }) => (
                  <div key={section} id={section}>
                    <MenuItemSection
                      key={section}
                      label={section}
                      menuId={menuId}
                      menuItems={menuItems}
                      menuItemSections={menuItemSections}
                      selectedMenuItems={selectedMenuItems}
                      setSelectedMenuItems={setSelectedMenuItems}
                      isDrawerOpen={isDrawerOpen}
                      selectedMenuItem={selectedMenuItem}
                      disabled={disabled}
                      removeMenuItem={handleRemoveMenuItem}
                      onClick={handleMenuItemClick}
                    />
                  </div>
                ))}
            </LazyMotion>

            <MenuItemActionBar
              menuItemSections={sectionNames}
              selectedMenuItems={selectedMenuItems}
              setSelectedMenuItems={setSelectedMenuItems}
            />
          </Stack>
        </ErrorBoundary>
      </Box>

      <MenuItemDrawer
        menuItem={selectedMenuItem}
        open={isDrawerOpen}
        disabled={disabled}
        recalculateCompliance={recalculateCompliance}
        onClose={() => setIsDrawerOpen(false)}
      />
    </Stack>
  );
};

export default memo(MenuItems);
