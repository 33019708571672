import { Chip, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Country } from 'constants/countries.constants';
import { COUNTRIES_ALL } from 'constants/recipes.constants';

import { CountryFlag } from 'components/@common';
import { CountrySelect } from 'components/@form';

type Props = {
  country: Country;
  size?: number;
  isReadOnly?: boolean;
};

const CountrySelector = ({ country, size = 22, isReadOnly }: Props) => (
  <Stack direction="row" gap={1} alignItems="center">
    <Typography variant="button">
      <FormattedMessage id="common.created_for" />
    </Typography>
    {isReadOnly && <CountryLabel country={country} size={size} />}
    {!isReadOnly && <CountrySelect />}
  </Stack>
);

export default CountrySelector;

const CountryLabel = ({ country, size }: Props) => {
  if (country === COUNTRIES_ALL) {
    return <Chip variant="labelDark" label={<FormattedMessage id="countries.ALL" />} />;
  }

  return <CountryFlag country={country} size={size} />;
};
