import { WarningRounded as WarningRoundedIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Collapse, Stack, Typography } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useVerifiedPercentagesMap } from 'utils/recipe-translations.utils';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useExportMenu, useUser } from 'queries';
import { Menu, MenuItemStatus } from 'types/menus.types';

import { InfoAlert } from 'components/@common';
import {
  ExportEmailsInput,
  ExporterModal,
  ExportLanguagesInput,
} from 'components/@common/ExporterModal';
import { Tooltip } from 'components/@common/Tooltip';

type Props = {
  menu: Menu;
  isDisabled: boolean;
};

const MenuExporter = ({ menu, isDisabled }: Props) => {
  const posthog = usePostHog();
  const { translationsFeature, menuExportFeature } = useFeatureFlags();
  const { user } = useUser();
  const { exportMenu, isPending: isExportingMenu } = useExportMenu();
  const [isOpen, setIsOpen] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>(
    translationsFeature ? [] : [DEFAULT_TRANSLATION_LOCALE],
  );

  const languageOptions = useVerifiedPercentagesMap(menu.menuItems);
  const amountOfUnmatched = useMemo(
    () => menu.menuItems.filter((menuItem) => menuItem.status === MenuItemStatus.Unmatched).length,
    [menu.menuItems],
  );

  const totalRecipesToTranslate = useMemo(() => {
    const translatableLanguages = languages.filter((lang) => lang !== DEFAULT_TRANSLATION_LOCALE);
    if (!translatableLanguages.length) return 0;

    const mostUntranslated = languageOptions
      .filter(({ language }) => translatableLanguages.includes(language))
      .sort((a, b) => {
        if (!a.totalCount || !a.translatedCount || !b.totalCount || !b.translatedCount) return 1;
        const aToTranslate = a.totalCount - a.translatedCount;
        const bToTranslate = b.totalCount - b.translatedCount;
        return bToTranslate - aToTranslate;
      })[0];

    return (mostUntranslated.totalCount || 0) - (mostUntranslated.translatedCount || 0);
  }, [languageOptions, languages]);

  const handleOpen = () => {
    setEmails(user ? [user.email] : []);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setEmails([]);
    setLanguages([]);
  };

  const handleExport = () => {
    exportMenu(
      { menuId: menu.id, emails, languages: languages.map((language) => ({ language })) },
      {
        onSuccess: () => {
          posthog.capture('exported_menu', { menu_id: menu.id });
        },
        onSettled: handleClose,
      },
    );
  };

  if (!menuExportFeature) return null;

  return (
    <>
      <Tooltip title={<FormattedMessage id="menus.export.tooltip" />}>
        <LoadingButton
          variant="contained"
          loading={isExportingMenu}
          color="info"
          disabled={isDisabled || !menu.menuItems.length}
          sx={{
            backgroundColor: 'white.100',
            color: 'info.main',
            '&:hover': {
              backgroundColor: 'grey.100',
            },
          }}
          onClick={handleOpen}
        >
          <FormattedMessage id="menus.export" />
        </LoadingButton>
      </Tooltip>

      <ExporterModal
        emails={emails}
        languages={languages}
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleExport}
        isSubmitting={isExportingMenu}
      >
        <ExportEmailsInput
          emails={emails}
          onChange={setEmails}
          description="recipes.actions.export.email.description"
        />
        <ExportLanguagesInput
          languageOptions={languageOptions}
          selectedLanguages={languages}
          onChange={setLanguages}
          prepend={
            <Stack>
              <Collapse in={!!amountOfUnmatched}>
                <Alert
                  color="error"
                  icon={<WarningRoundedIcon sx={{ width: 24 }} />}
                  sx={{ mb: 1 }}
                >
                  <FormattedMessage
                    id="menus.export.dialog.unmatched_warning"
                    values={{ amount: amountOfUnmatched }}
                  />
                </Alert>
              </Collapse>
              <InfoAlert isOpen={!!totalRecipesToTranslate} sx={{ mb: 1 }}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="menus.export.dialog.translation_info"
                    values={{ amount: totalRecipesToTranslate }}
                  />
                </Typography>
              </InfoAlert>
            </Stack>
          }
          description="recipes.actions.export.languages.description"
          languageVerifiedTooltip="menus.export.dialog.verified_tooltip"
          languageAITooltip="menus.export.dialog.ai_tooltip"
        />
      </ExporterModal>
    </>
  );
};
export default MenuExporter;
