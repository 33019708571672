import { Print as PrintIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { useSortLanguages } from 'hooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useExportRecipe, useUser } from 'queries';
import { useNotifications } from 'services/snackbar';
import { Translation } from 'types/recipe-translations.types';
import { RecipeFormValues } from 'types/recipes.types';

import {
  ExportEmailsInput,
  ExporterModal,
  ExportLanguagesInput,
} from 'components/@common/ExporterModal';

type Props = {
  recipeTranslations: Translation[];
};

const RecipeExporter = ({ recipeTranslations }: Props) => {
  const intl = useIntl();
  const { user } = useUser();
  const notifications = useNotifications();
  const { translationsFeature, recipeExportFeature } = useFeatureFlags();
  const { exportRecipe, isPending: isExportingRecipe } = useExportRecipe();

  const recipeId = useWatch<RecipeFormValues>({ name: 'id' }) as number;
  const languageOptions = useSortLanguages(recipeTranslations);

  const [isOpen, setIsOpen] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>(
    translationsFeature ? [] : [DEFAULT_TRANSLATION_LOCALE],
  );

  const handleOpen = () => {
    setEmails(user ? [user.email] : []);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setEmails([]);
    setLanguages([]);
  };

  const handleExport = () => {
    let error = false;
    languages.forEach((language) => {
      exportRecipe(
        { recipeId, emails, language },
        {
          onError: () => {
            error = true;
          },
        },
      );
    });
    handleClose();
    if (error) {
      notifications.error({
        message: intl.formatMessage({ id: 'recipes.actions.export.error' }),
      });
    } else {
      notifications.success({
        message: intl.formatMessage({ id: 'recipes.actions.export.success' }),
      });
    }
  };

  if (!recipeExportFeature) return null;

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ color: isOpen ? 'primary.dark' : undefined }}>
        <PrintIcon />
      </IconButton>

      <ExporterModal
        emails={emails}
        languages={languages}
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleExport}
        isSubmitting={isExportingRecipe}
        titleText="recipes.actions.export.title"
        submitText="recipes.actions.export"
      >
        <ExportEmailsInput
          emails={emails}
          onChange={setEmails}
          description="recipes.actions.export.email.description"
        />
        <ExportLanguagesInput
          description="recipes.actions.export.languages.description"
          languageOptions={languageOptions}
          selectedLanguages={languages}
          onChange={setLanguages}
        />
      </ExporterModal>
    </>
  );
};

export default RecipeExporter;
