import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  children?: ReactNode;
  onSubmit: () => void;
  isSubmitting?: boolean;
  emails: string[];
  languages: string[];
  isOpen: boolean;
  onClose: () => void;
  titleText?: I18nKey;
  submitText?: I18nKey;
}

const ExporterModal = ({
  children,
  isSubmitting,
  emails = [],
  languages = [],
  onSubmit,
  isOpen,
  onClose,
  titleText = 'menus.export.dialog.title',
  submitText = 'menus.export.dialog.generate',
}: Props) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <FormattedMessage id={titleText} />
          <Button variant="text" sx={{ color: 'global01.main' }} onClick={onClose}>
            <FormattedMessage id="common.close" />
          </Button>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack gap={4}>{children}</Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="text" sx={{ color: 'global01.main' }} onClick={onClose}>
          <FormattedMessage id="common.cancel" />
        </Button>

        <LoadingButton
          disabled={!languages.length || !emails.length}
          variant="contained"
          loading={isSubmitting}
          onClick={onSubmit}
        >
          <FormattedMessage id={submitText} values={{ amount: languages.length }} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExporterModal;
