import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Translate as TranslateIcon,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { Routes } from 'constants/routes.constants';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useIsHQ } from 'hooks/useIsHQ';
import { useArchiveRecipe, useCreateRecipeVariant, useTranslateRecipe } from 'queries';
import { useTranslationLocale } from 'store';
import { RecipeFormValues, RecipeStatus } from 'types/recipes.types';

import { ConfirmationDialog } from 'components/@common';
import ActionMenu from 'components/@common/ActionMenu/ActionMenu';
import ActionMenuItem from 'components/@common/ActionMenu/ActionMenuItem';

type Props = {
  isReadOnly: boolean;
  isTranslating: boolean;
};

const RecipeActionsMenu = ({ isReadOnly, isTranslating }: Props) => {
  const { translationsFeature } = useFeatureFlags();
  const intl = useIntl();
  const posthog = usePostHog();
  const { setTranslationLocale } = useTranslationLocale();
  const { getValues } = useFormContext<RecipeFormValues>();
  const { createRecipeVariant, isPending: isCreatingRecipeVariant } = useCreateRecipeVariant();
  const { deleteRecipe, isPending: isDeletingRecipe } = useArchiveRecipe();
  const { translateRecipe, isPending: isTranslatingRecipe } = useTranslateRecipe();
  const isHQ = useIsHQ();

  const navigate = useNavigate();

  const status = useWatch<RecipeFormValues>({ name: 'status' }) as RecipeFormValues['status'];

  const handleCreateRecipeVariant = () => {
    const formValues = getValues();

    posthog.capture('create_recipe', {
      type: 'variant',
      source: formValues.status === RecipeStatus.Published ? 'cookbook' : 'experimental_kitchen',
      name: formValues.name,
    });

    createRecipeVariant(formValues);
  };

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const handleDeleteRecipe = () => {
    deleteRecipe(getValues('id'), {
      onSuccess: () =>
        navigate(status === RecipeStatus.Published ? Routes.Cookbook : Routes.ExperimentalKitchen, {
          replace: true,
        }),
    });

    setOpenConfirmDelete(false);
  };

  const handleTriggerTranslation = () => {
    if (!translationsFeature) return;
    setTranslationLocale(DEFAULT_TRANSLATION_LOCALE);
    translateRecipe({ recipeId: getValues('id') });
  };

  const isDraft = [RecipeStatus.Draft, RecipeStatus.Rejected].includes(status);

  return (
    <Box className="no-print">
      <ActionMenu>
        <ActionMenuItem
          Icon={ContentCopyIcon}
          onClick={handleCreateRecipeVariant}
          isLoading={isCreatingRecipeVariant}
          label={<FormattedMessage id="recipes.actions.variant.create" />}
        />
        {((isHQ && status !== RecipeStatus.Archived) || isDraft) && (
          <ActionMenuItem
            Icon={DeleteIcon}
            isLoading={isDeletingRecipe}
            onClick={() => setOpenConfirmDelete(true)}
            label={
              <FormattedMessage
                id="general.actions.archive"
                values={{ type: intl.formatMessage({ id: 'recipes.label.singular' }) }}
              />
            }
          />
        )}

        {translationsFeature && isHQ && isReadOnly && !isDraft && (
          <ActionMenuItem
            Icon={TranslateIcon}
            isLoading={isTranslatingRecipe || isTranslating}
            onClick={handleTriggerTranslation}
            label={<FormattedMessage id="recipes.translation.trigger_translation" />}
          />
        )}
      </ActionMenu>

      <ConfirmationDialog
        title={
          <FormattedMessage
            id="general.actions.archive.confirm.title"
            values={{ type: intl.formatMessage({ id: 'recipes.label.singular' }) }}
          />
        }
        message={<FormattedMessage id="general.actions.delete.confirm.text" />}
        onConfirm={handleDeleteRecipe}
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      />
    </Box>
  );
};

export default RecipeActionsMenu;
