import { useMemo } from 'react';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { useLanguages } from 'queries';
import { RecipeTranslationStatus, Translation } from 'types/recipe-translations.types';

const STATUS_ORDER = {
  [RecipeTranslationStatus.Main]: 0,
  [RecipeTranslationStatus.Verified]: 1,
  [RecipeTranslationStatus.AI]: 2,
  [RecipeTranslationStatus.Pending]: 3,
  [RecipeTranslationStatus.Error]: 4,
};

export const useSortLanguages = (recipeTranslations: Translation[]): Translation[] => {
  const { languages } = useLanguages();

  /* Sort languages by verified state */
  const sortedLanguages = useMemo(
    () =>
      languages
        .map((language) => {
          const translation = recipeTranslations.find((t) => t.language === language);
          const isDefault = language === DEFAULT_TRANSLATION_LOCALE;

          return {
            ...translation,
            language,
            status: isDefault
              ? RecipeTranslationStatus.Main
              : translation?.status || RecipeTranslationStatus.Error,
          } as Translation;
        })
        .sort((a, b) => {
          return STATUS_ORDER[a.status] - STATUS_ORDER[b.status];
        }),
    [languages, recipeTranslations],
  );

  return sortedLanguages;
};
