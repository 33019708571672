import { Rating, Skeleton, TableCell, TableRow } from '@mui/material';

import { RECIPE_TABLE_IMAGE_HEIGHT, RECIPE_TABLE_IMAGE_WIDTH } from 'constants/recipes.constants';
import { RecipeTableColumns, RecipeTableHeader } from 'types/recipes.types';

type Props = {
  tableHeaders: RecipeTableHeader[];
};

const RecipeTableSkeleton = ({ tableHeaders }: Props) => {
  const usedHeaders = tableHeaders.map(({ id }) => id);

  return Array.from({ length: 12 }).map((_, index) => (
    <TableRow key={index} hover sx={{ backgroundColor: 'bg.paper' }}>
      <TableCell key={RecipeTableColumns.Image} align="center">
        <Skeleton height={RECIPE_TABLE_IMAGE_HEIGHT} width={RECIPE_TABLE_IMAGE_WIDTH} />
      </TableCell>
      {usedHeaders.map((header) => {
        switch (header) {
          case RecipeTableColumns.Name:
            return (
              <TableCell key={RecipeTableColumns.Name}>
                <Skeleton height={16} width={180} sx={{ mb: 0.5 }} />
                <Skeleton height={14} width={32} />
              </TableCell>
            );
          case RecipeTableColumns.OnBrand:
            return (
              <TableCell key={RecipeTableColumns.OnBrand}>
                <Skeleton variant="circular" height={32} width={112} />
              </TableCell>
            );
          case RecipeTableColumns.Type:
            return (
              <TableCell key={RecipeTableColumns.Type}>
                <Skeleton height={16} width={48} />
              </TableCell>
            );
          case RecipeTableColumns.Season:
            return (
              <TableCell key={RecipeTableColumns.Season}>
                <Skeleton height={16} width={96} />
              </TableCell>
            );
          case RecipeTableColumns.UpdatedAt:
            return (
              <TableCell key={RecipeTableColumns.UpdatedAt}>
                <Skeleton height={16} width={96} />
              </TableCell>
            );
          case RecipeTableColumns.Performance:
            return (
              <TableCell key={RecipeTableColumns.Performance}>
                <Rating value={0} />
              </TableCell>
            );
          case RecipeTableColumns.Translation:
            return (
              <TableCell key={RecipeTableColumns.Translation} align="center">
                <Skeleton height={16} width={40} />
              </TableCell>
            );
          case RecipeTableColumns.Countries:
            return (
              <TableCell key={RecipeTableColumns.Countries} align="center">
                <Skeleton variant="circular" height={20} width={20} />
              </TableCell>
            );
          case RecipeTableColumns.Status:
            return (
              <TableCell key={RecipeTableColumns.Status}>
                <Skeleton variant="circular" height={32} width={64} />
              </TableCell>
            );
        }
      })}
    </TableRow>
  ));
};

export default RecipeTableSkeleton;
