import { Checkbox, List, MenuItem, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { getLanguageKey } from 'utils/recipe-translations.utils';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { CountedTranslation } from 'types/recipe-translations.types';

import { TranslationStatus } from 'components/@recipe-translations/TranslationStatus';

import ExporterSection from './ExporterSection';

type Props = {
  selectedLanguages: string[];
  languageOptions: CountedTranslation[];
  onChange: (languages: string[]) => void;
  description: I18nKey;
  prepend?: ReactNode;
  languageVerifiedTooltip?: I18nKey;
  languageAITooltip?: I18nKey;
};

const ExportLanguagesInput = ({
  selectedLanguages,
  languageOptions,
  onChange,
  description,
  prepend,
  languageVerifiedTooltip,
  languageAITooltip,
}: Props) => {
  const { translationsFeature } = useFeatureFlags();

  if (!translationsFeature) return null;

  const handleLanguageClick = (language: string) => {
    onChange(
      selectedLanguages.includes(language)
        ? selectedLanguages.filter((l) => l !== language)
        : [...selectedLanguages, language],
    );
  };

  return (
    <ExporterSection
      title="menus.export.dialog.languages.title"
      description={description}
      index={2}
    >
      {prepend}
      <List
        sx={(theme) => ({
          bgcolor: 'grey.50',
          borderRadius: theme.mixins.borderRadius.sm,
          maxHeight: theme.spacing(40),
          overflow: 'auto',
        })}
      >
        {languageOptions.map(({ language, status, verifiedPercentage }) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageClick(language)}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Stack direction="row" alignItems="center">
              <Checkbox
                color="default"
                disableRipple
                size="small"
                checked={selectedLanguages.includes(language)}
                sx={(theme) => ({ color: `${theme.palette.black[60]} !important` })}
              />
              <Typography>
                <FormattedMessage id={getLanguageKey(language)} />
              </Typography>
            </Stack>
            <TranslationStatus
              verifiedPercentage={verifiedPercentage}
              verifiedStatus={status}
              verifiedTooltip={languageVerifiedTooltip}
              aiTooltip={languageAITooltip}
            />
          </MenuItem>
        ))}
      </List>
    </ExporterSection>
  );
};

export default ExportLanguagesInput;
